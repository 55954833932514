import {
  BOOK_MAKER_SAVE_START,
  BOOK_MAKER_SAVE_SUCCESS,
  BOOK_MAKER_SAVE_ERROR,

  BOOK_MAKER_UPDATE_START,
  BOOK_MAKER_UPDATE_SUCCESS,
  BOOK_MAKER_UPDATE_ERROR,

  BOOK_MAKERS_FETCH_START,
  BOOK_MAKER_FETCH_SUCCESS,
  BOOK_MAKER_FETCH_ERROR,

  BOOK_MAKER_DELETE_START,
  BOOK_MAKER_DELETE_SUCCESS,
  BOOK_MAKER_DELETE_ERROR,

} from '../actions/bookmakerActions';


const initialState = {
  bookmakerData: null,
  isProcessing: false,
  bookmakerList: null,
  error: ""
};


function bookMakerReducer(state = initialState, action) {
  switch (action.type) {
    // ============================ Bookmaker Saving ============================
    case BOOK_MAKER_SAVE_START:
      return Object.assign({}, state, {
        isProcessing: true,
      });

    case BOOK_MAKER_SAVE_SUCCESS:
      return {
        ...state,
        bookmakerData: action.payload.data,
        isProcessing: false,
        error: null
      };

    case BOOK_MAKER_SAVE_ERROR:
      return Object.assign({}, state, {
        ...state,
        isProcessing: false,
        error: 'Create Failed'
      });

    // ============================ Bookmaker Updating ========================
    case BOOK_MAKER_UPDATE_START:
      return Object.assign({}, state, {
        isProcessing: true,
      });

    case BOOK_MAKER_UPDATE_SUCCESS:
      return {
        ...state,
        bookmakerData: action.payload.data,
        isProcessing: false,
        error: null
      };

    case BOOK_MAKER_UPDATE_ERROR:
      return {
        ...state,
        isProcessing: false,
        error: 'Update Failed'
      };

    // ============================ Bookmaker Fetching ============================

    case BOOK_MAKERS_FETCH_START:
      return Object.assign({}, state, {
        isProcessing: true,
      });

    case BOOK_MAKER_FETCH_SUCCESS:
      return {
        ...state,
        bookmakerData: action.payload.data,
        isProcessing: false,
        error: null
      };
    case BOOK_MAKER_FETCH_ERROR:
      return {
        ...state,
        isProcessing: false,
        error: 'Fetch Failed'
      };

    // ============================ Bookmaker Deletion ============================
    case BOOK_MAKER_DELETE_START:
      return Object.assign({}, state, {
        isProcessing: true,
      });

    case BOOK_MAKER_DELETE_SUCCESS:
      return {
        ...state,
        bookmakerData: action.payload.data,
        isProcessing: false,
        error: null
      };
    case BOOK_MAKER_DELETE_ERROR:
      return {
        ...state,
        isProcessing: false,
        error: 'Deletion Failed'
      };

    default:
      return state;
  }
}

export default bookMakerReducer;


