import { toast } from "react-toastify";
import {
  sendGenerationEmailAll,
  sendPromoEmailAll,
  sendFridayNightEmail,
  getEmailsSubjects,
  editEmailsSubjects,
  getContactUsEmail,
  getInboxEmails,
  getEmailStat,
} from "../../config/config";

export const GET_EMAIL_SUBJECT = "GET_EMAIL_SUBJECT";
export const GET_CONTACT_US_SUCCESS = "GET_CONTACT_US_SUCCESS";

export const GET_INBOX_START = "GET_INBOX_START";
export const GET_INBOX_SUCCESS = "GET_INBOX_SUCCESS";
export const GET_INBOX_ERROR = "GET_INBOX_ERROR";

export const GET_STAT_MAILER_START = "GET_STAT_MAILER_START";
export const GET_STAT_MAILER_SUCCESS = "GET_STAT_MAILER_SUCCESS";
export const GET_STAT_MAILER_ERROR = "GET_STAT_MAILER_ERROR";

export const DISABLE_BUTTONS = "DISABLE_BUTTONS";

export const actions = {
  sendGenerationEmailsAll: () => async (dispatch) => {
    try {
      let response = await sendGenerationEmailAll();
      if (response.status === 200) {
        toast.success("BOOM, EMAIL SENT SUCCESS");
      } else {
        toast.error(response.error);
      }
    } catch (error) {
      toast.error("ERROR SENDING EMAIL TO ALL CLIENTS. CONTACT SUPPORT");
    }
  },

  sendPromoEmailsAll: () => async (dispatch) => {
    try {
      let response = await sendPromoEmailAll();
      if (response.status === 200) {
        toast.success("BOOM, EMAIL SENT SUCCESS");
      } else {
        toast.error(response.error);
      }
    } catch (error) {
      toast.error("ERROR SENDING EMAIL TO ALL CLIENTS. CONTACT SUPPORT");
    }
  },

  sendFridayNight: () => async (dispatch) => {
    try {
      let response = await sendFridayNightEmail();
      if (response.status === 200) {
        toast.success("BOOM, EMAIL SENT SUCCESS");
      } else {
        toast.error(response.error);
      }
    } catch (error) {
      toast.error("ERROR SENDING EMAIL TO ALL CLIENTS. CONTACT SUPPORT");
    }
  },

  getEmailSubjects: () => async (dispatch) => {
    try {
      let response = await getEmailsSubjects();
      if (response.status === 200) {
        // console.log(response.buttonDisabled)
        dispatch({
          type: GET_EMAIL_SUBJECT,
          payload: {
            emailGeneration: response.emailGeneration,
            emailPromo: response.emailPromo,
            fridayNight: response.fridayNight,
            buttonDisabled: response.buttonDisabled,
          },
        });
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      toast.error("ERROR GETTING EMAIL SUBJECT. CONTACT SUPPORT");
    }
  },

  disableButton: () => async (dispatch) => {
    dispatch({
      type: DISABLE_BUTTONS,
    });
  },

  getContactUsEmails: () => async (dispatch) => {
    try {
      let response = await getContactUsEmail();
      if (response.status === 200) {
        //console.log(response)
        dispatch({
          type: GET_CONTACT_US_SUCCESS,
          payload: {
            contactUsEmails: response.data,
          },
        });
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      toast.error("ERROR GETTING CONTACT US EMAILS. CONTACT SUPPORT");
    }
  },

  editEmailSubjects: (data) => async (dispatch) => {
    try {
      let response = await editEmailsSubjects(data);
      if (response.status === 200) {
        dispatch({
          type: GET_EMAIL_SUBJECT,
          payload: {
            emailGeneration: response.emailGeneration,
            emailPromo: response.emailPromo,
            fridayNight: response.fridayNight,
          },
        });
        toast.success("EMAIL SUBJECT UPDATED");
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      toast.error("ERROR UPDATING EMAIL SUBJECT. CONTACT SUPPORT");
    }
  },

  getInbox: () => async (dispatch) => {
    try {
      dispatch({
        type: GET_INBOX_START,
      });

      let response = await getInboxEmails();
      if (response.status === 200) {
        dispatch({
          type: GET_INBOX_SUCCESS,
          payload: {
            inbox: response.data,
          },
        });
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      dispatch({
        type: GET_INBOX_ERROR,
      });
      toast.error("ERROR GETTING INBOX EMAIL. CONTACT SUPPORT");
    }
  },

  getStatMailer: () => async (dispatch) => {
    try {
      dispatch({
        type: GET_STAT_MAILER_START,
      });

      let response = await getEmailStat();
      if (response.status === 200) {
        dispatch({
          type: GET_STAT_MAILER_SUCCESS,
          payload: {
            statMailer: response.data.data,
          },
        });
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      dispatch({
        type: GET_STAT_MAILER_ERROR,
      });
      toast.error("ERROR GETTING STAT MAILS");
    }
  },
};
