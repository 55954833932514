import {
  getUnibetDB, updateUnibetDB, fixBookieOrderDB, generateNADB,
  UnibetCheckEmptyId, checkSBprices, InsertOrUpdateRaces,
  InsertOrUpdateHorses, InsertLBID, InsertSBID, getSubPlan, generateSiteMap, cleanLocalhosts,
  UpdateTimeAndTrackCoditionToday, SendTipOfTheDay, SBODDS, LBSBDetails, GetSBDetails, UpdateNames,
  generateAIJsonReport, UpdateHorsesIDs, getVenuesState, updateVenueState, AssignThirdUpHorseProfile,
  enableEmailButtons, updateVenueDetails, query, pm2RunningInstances,
  getVenueDetails, pm2Restart , pm2Stop , updateOddsWithFlucsUbSb , updateResultsUb, getAllRacesRatings , DataForAfterTomorrow, DataForToday, DataForTomorrow
} from '../../config/config'
import { toast } from 'react-toastify'

import venuesActions from './venuesActions';


export const API_FETCH_STARTED = 'API_FETCH_STARTED'
export const API_FETCH_SUCCESS = 'API_FETCH_SUCCESS'
export const API_FETCH_ERROR = 'API_FETCH_ERROR'

export const SB_FETCH_STARTED = 'SB_FETCH_STARTED'
export const SB_FETCH_SUCCESS = 'SB_FETCH_SUCCESS'
export const SB_FETCH_ERROR = 'SB_FETCH_ERROR'

export const VENUESTATE_FETCH_STARTED = 'VENUESTATE_FETCH_STARTED'
export const VENUESTATE_FETCH_SUCCESS = 'VENUESTATE_FETCH_SUCCESS'
export const VENUESTATE_FETCH_ERROR = 'VENUESTATE_FETCH_ERROR'

export const GET_SUBSCRIPTION_PLAN = 'GET_SUBSCRIPTION_PLAN'

export const GET_VENUE_DETAILS_STARTED = 'GET_VENUE_DETAILS_STARTED'
export const GET_VENUE_DETAILS_SUCCESS = 'GET_VENUE_DETAILS_SUCCESS'
export const GET_VENUE_DETAILS_ERROR = 'GET_VENUE_DETAILS_ERROR'

export const UPDATE_VENUE_DETAILS_STARTED = 'UPDATE_VENUE_DETAILS_STARTED'
export const UPDATE_VENUE_DETAILS_SUCCESS = 'UPDATE_VENUE_DETAILS_SUCCESS'
export const UPDATE_VENUE_DETAILS_ERROR = 'UPDATE_VENUE_DETAILS_ERROR'

export const QUERY_START = 'QUERY_START'
export const QUERY_SUCCESS = 'QUERY_SUCCESS'
export const QUERY_ERROR = 'QUERY_ERROR'

export const PM2_RESTART_START = 'PM2_RESTART_START'
export const PM2_RESTART_SUCCESS = 'PM2_RESTART_SUCCESS'
export const PM2_RESTART_ERROR = 'PM2_RESTART_ERROR'

export const PM2_STOP_START = 'PM2_STOP_START'
export const PM2_STOP_SUCCESS = 'PM2_STOP_SUCCESS'
export const PM2_STOP_ERROR = 'PM2_STOP_ERROR'

export const PM2_LIST_INSTANCES_START = 'PM2_LIST_INSTANCES_START'
export const PM2_LIST_INSTANCES_SUCCESS = 'PM2_LIST_INSTANCES_SUCCESS'
export const PM2_LIST_INSTANCES_ERROR = 'PM2_LIST_INSTANCES_ERROR'


function selectErrorMessage(error) {
  if (error && error.response && error.response.data) {
    const data = error.response.data;

    if (data.error && data.error.message) {
      return data.error.message;
    }

    return String(data);
  }

  return error.message || 'ERROR';
}

const actions = {

  doFetch: () => async (dispatch) => {
    try {
      dispatch({
        type: API_FETCH_STARTED,
      });

      let response = await getUnibetDB()

      dispatch({
        type: API_FETCH_SUCCESS,
        payload: {
          data: response,
        },
      });
    } catch (error) {
      toast.error(selectErrorMessage(error))

      dispatch({
        type: API_FETCH_ERROR,
      });
    }
  },

  getVenuesTableData: () => async (dispatch) => {
    try {
      dispatch({
        type: VENUESTATE_FETCH_STARTED,
      });

      let response = await getVenuesState()

      dispatch({
        type: VENUESTATE_FETCH_SUCCESS,
        payload: {
          data: response,
        },
      });
    } catch (error) {
      toast.error(selectErrorMessage(error))

      dispatch({
        type: VENUESTATE_FETCH_ERROR,
      });
    }
  },

  updateVenueState: (data) => async (dispatch) => {
    try {
      await updateVenueState({ venueID: data.venue_id, State: data.venue_state }).then(res => {
        toast.success('Venue State Updated successfuly');
      })
    } catch (error) {
      toast.error(selectErrorMessage(error))
    }
  },


  updateUBID: (data) => async (dispatch) => {
    try {
      await updateUnibetDB({ pointID: data.point_id, UBvenueID: data.unibet_venue_id, SBvenueID: data.sb_venue_id, TrackCode: data.trackcode, LBvenueID: data.ladbrokes_venue_id }).then(res => {
        toast.success('UNIBET ID updated successfuly');
      })
    } catch (error) {
      toast.error(selectErrorMessage(error))
    }
  },

  fixBookieOrder: (date) => async (dispatch) => {
    try {
      await fixBookieOrderDB({ passingDate: date }).then(res => {
        toast.success('Bookie Order FIXED');
      })
    } catch (error) {
      toast.error(selectErrorMessage(error))
    }
  },

  generateNA: (date) => async (dispatch) => {
    try {
      await generateNADB(date).then(res => {
        toast.success('N/A generated successfully');
      })
    } catch (error) {
      toast.error(selectErrorMessage(error))
    }
  },

  checkUnibetId: () => async (dispatch) => {
    try {
      await UnibetCheckEmptyId().then(res => {
        toast.success('Unibet Ids Checked successfully');
      })
    } catch (error) {
      toast.error('Error checking Unibet Id please contact Team')
    }
  },

  checkSBprices: () => async (dispatch) => {
    try {
      await checkSBprices().then(res => {
        toast.success('Sports Betting Checked Successfully');
      })
    } catch (error) {
      toast.error('Error checking SportsBetting Prices please contact Team')
    }
  },

  updateUBRaces: () => async (dispatch) => {
    try {
      await InsertOrUpdateRaces().then(res => {
        toast.success('Update Venues successfuly');
      })
    } catch (error) {
      toast.error(selectErrorMessage(error))
    }
  },

  updateNames: () => async (dispatch) => {
    try {
      await UpdateNames().then(res => {
        toast.success('Update Venues successfuly');
      })
    } catch (error) {
      toast.error(selectErrorMessage(error))
    }
  },

  updateDataToday: () => async (dispatch) => {
    try {
      await DataForToday().then(res => {
        toast.success('Update Data successfuly');
      })
    } catch (error) {
      toast.error(selectErrorMessage(error))
    }
  },
  updateDataTomorrow: () => async (dispatch) => {
    try {
      await DataForTomorrow().then(res => {
        toast.success('Update Data successfuly');
      })
    } catch (error) {
      toast.error(selectErrorMessage(error))
    }
  },
  updateDataAfterTomorrow: () => async (dispatch) => {
    try {
      await DataForAfterTomorrow().then(res => {
        toast.success('Update Data successfuly');
      })
    } catch (error) {
      toast.error(selectErrorMessage(error))
    }
  },

  updateUBHorses: () => async (dispatch) => {
    try {
      await InsertOrUpdateHorses().then(res => {
        toast.success('Update horses successfuly');
      })
    } catch (error) {
      toast.error(selectErrorMessage(error))
    }
  },

  updateLBID: () => async (dispatch) => {
    try {
      await InsertLBID().then(res => {
        toast.success('Update ladbrokes Id successfuly');
      })
    } catch (error) {
      toast.error(selectErrorMessage(error))
    }
  },

  updateSBID: () => async (dispatch) => {
    try {
      await InsertSBID().then(res => {
        toast.success('Update SportsBetting Id successfuly');
      })
    } catch (error) {
      toast.error(selectErrorMessage(error))
    }
  },

  updateCondAndTime: () => async (dispatch) => {
    try {
      await UpdateTimeAndTrackCoditionToday().then(res => {
        toast.success('Update track condition and race time successfuly');
      })
    } catch (error) {
      toast.error(selectErrorMessage(error))
    }
  },

  TipOfTheDay: () => async (dispatch) => {
    try {
      await SendTipOfTheDay().then(res => {
        toast.success('tip of The day sent');
      })
    } catch (error) {
      toast.error(selectErrorMessage(error))
    }
  },

  UpdateSBOdds: () => async (dispatch) => {
    try {
      await SBODDS().then(res => {
        toast.success('UPDATe SB ODDS underway');
      })
    } catch (error) {
      toast.error(selectErrorMessage(error))
    }
  },

  UpdateLBSBDetails: () => async (dispatch) => {
    try {
      await LBSBDetails().then(res => {
        toast.success('UPDATE LB SB underway');
      })
    } catch (error) {
      toast.error(selectErrorMessage(error))
    }
  },

  UpdateHorseIDS: () => async (dispatch) => {
    try {
      await UpdateHorsesIDs().then(res => {
        toast.success('UPDATE Jockeys success');
      })
    } catch (error) {
      toast.error(selectErrorMessage(error))
    }
  },

  UpdateThirdUp: () => async (dispatch) => {
    try {
      await AssignThirdUpHorseProfile().then(res => {
        toast.success('Third Up success');
      })
    } catch (error) {
      toast.error(selectErrorMessage(error))
    }
  },

  GetRacesSBDetails: () => async (dispatch) => {
    try {
      dispatch({
        type: SB_FETCH_STARTED,
      });

      await GetSBDetails().then((response) => {
        dispatch({
          type: SB_FETCH_SUCCESS,
          payload: {
            data: response.data,
          },
        });
      })
    } catch (error) {
      toast.error(selectErrorMessage(error))

      dispatch({
        type: SB_FETCH_ERROR,
      });
    }
  },


  getSubscriptionPlan: () => async (dispatch) => {
    try {
      await getSubPlan().then((response) => {
        if (response.status === 200) {
          // console.log(response.data)
          dispatch({
            type: GET_SUBSCRIPTION_PLAN,
            payload: {
              data: response.data,
            },
          });
        }
      })
    } catch (error) {
      toast.error('ERROR GETTING SUBSCRIPTION PLAN')
    }
  },


  generateSiteMapXML: () => async (dispatch) => {
    try {
      await generateSiteMap().then((response) => {
        if (response.status === 200) {
          toast.success('SITEMAP GENERATED')
        } else {
          toast.error('ERROR GENERATING SITEMAP')
        }
      })
    } catch (error) {
      toast.error('ERROR GENERATING SITEMAP')
    }
  },


  cleanLocalhostlogins: () => async (dispatch) => {
    try {
      await cleanLocalhosts().then((response) => {
        if (response.status === 200) {
          toast.success('Clean Done')
        } else {
          toast.error('ERROR cleaning localhosts')
        }
      })
    } catch (error) {
      toast.error('ERROR cleaning localhosts')
    }
  },

  generateAIJson: () => async (dispatch) => {
    try {
      await generateAIJsonReport().then((response) => {
        if (response.status === 200) {
          toast.success('Generated Successfully')
        } else {
          toast.error('Error Generating report')
        }
      })
    } catch (error) {
      toast.error('Error Generating report')
    }
  },

  enableButtons: () => async (dispatch) => {
    try {
      await enableEmailButtons().then((response) => {
        if (response.status === 200) {
          toast.success('finished Successfully')
        } else {
          toast.error('Error. Please contact support')
        }
      })
    } catch (error) {
      toast.error('Error. Please contact support')
    }
  },



  getVenueDetails: (data) => async (dispatch) => {
    try {
      dispatch({
        type: GET_VENUE_DETAILS_STARTED,
      });

      await getVenueDetails(data).then(response => {
        if (response.status === 200) {
          dispatch({
            type: GET_VENUE_DETAILS_SUCCESS,
            payload: {
              data: response.data,
            },
          });
        } else {
          dispatch({
            type: GET_VENUE_DETAILS_ERROR,
          });
        }
      })

    } catch (error) {
      toast.error(error)

      dispatch({
        type: GET_VENUE_DETAILS_ERROR,
      });
    }
  },


  updateVenueDetails: (data) => async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_VENUE_DETAILS_STARTED,
      });

      await updateVenueDetails(data).then(response => {
        if (response.status === 200) {
          dispatch({
            type: UPDATE_VENUE_DETAILS_SUCCESS,
          });
          toast.success("Venue Details Updated ")
        } else {
          dispatch({
            type: UPDATE_VENUE_DETAILS_ERROR,
          });
          toast.error("error updating venue details ")

        }
      })

    } catch (error) {
      toast.error('error updating venue details: ' + error)

      dispatch({
        type: UPDATE_VENUE_DETAILS_ERROR,
      });
    }
  },

  queryDb: (data) => async (dispatch) => {
    try {
      dispatch({
        type: QUERY_START,
      });

      await query(data).then(response => {
        if (response.status === 200) {
          dispatch({
            type: QUERY_SUCCESS,
            payload: {
              data: response.data
            }
          });
          toast.success("Query Success")
        } else {
          dispatch({
            type: QUERY_ERROR,
          });
          toast.error("Query Error")
        }
      })

    } catch (error) {
      toast.error('Query Error: ' + error)
      dispatch({
        type: QUERY_ERROR,
      });
    }
  },

  pm2Restart: (data) => async (dispatch) => {
    try {
      dispatch({
        type: PM2_RESTART_START,
      });

      await pm2Restart(data).then(response => {
        if (response.status === 200) {
          dispatch({
            type: PM2_RESTART_SUCCESS,
            payload: {
              data: response.data
            }
          });
          toast.success("PM2_RESTART Success")
          dispatch(actions.pm2ListInstances({loading:true}))
        } else {
          dispatch({
            type: PM2_RESTART_ERROR,
          });
          dispatch(actions.pm2ListInstances({loading:true}))
          toast.error("PM2_RESTART Error")
        }
      })

    } catch (error) {
      toast.error('PM2_RESTART Error: ' + error)
      dispatch({
        type: PM2_RESTART_ERROR,
      });
      dispatch(actions.pm2ListInstances({loading:true}))
    }
  },

  pm2Stop: (data) => async (dispatch) => {
    try {
      dispatch({
        type: PM2_STOP_START,
      });

      await pm2Stop(data).then(response => {
        if (response.status === 200) {
          dispatch({
            type: PM2_STOP_SUCCESS,
            payload: {
              data: response.data
            }
          });
          toast.success("PM2_STOP Success")
          dispatch(actions.pm2ListInstances({loading:true}))
        } else {
          dispatch({
            type: PM2_STOP_ERROR,
          });
          dispatch(actions.pm2ListInstances({loading:true}))
          toast.error("PM2_STOP Error")
        }
      })

    } catch (error) {
      toast.error('PM2_STOP Error: ' + error)
      dispatch({
        type: PM2_STOP_ERROR,
      });
      dispatch(actions.pm2ListInstances({loading:true}))

    }
  },

  pm2ListInstances: (data) => async (dispatch) => {
    try {
      if (data.loading === true) {
        dispatch({
          type: PM2_LIST_INSTANCES_START
        })
      }
      await pm2RunningInstances().then(response => {
        if (response.status === 200) {
          dispatch({
            type: PM2_LIST_INSTANCES_SUCCESS,
            payload: response.data
          })
        } else {
          dispatch({
            type: PM2_LIST_INSTANCES_ERROR,
          })
        }
      })

    } catch (err) {
      dispatch({
        type: PM2_LIST_INSTANCES_ERROR
      })
    }
  },

  updateOddsWithFlucsUbSb:(data)=> async (dispatch)=>{
    try{
      await updateOddsWithFlucsUbSb(data).then(response=>{
        if(response.status===200){
          toast.success(response.message)
        }else if(response.status===201){
          toast.warning(response.message)
        }else{
          toast.error(response.message)
        }
      })
    }
    catch(err){
      toast.error("No Venues oddss where updated")
    }
  } ,

  updateResultsUb:(data)=>async (dispatch)=>{
    try{
      await updateResultsUb(data).then(response=>{
        if(response.status ===200){
          toast.success(response.data.message)
          dispatch(venuesActions.doFindAllInfo(data?.point_id))
        }else{
          toast.error(response.data.message)
        }
      })

    }catch(err){
      toast.error('ERROR:'+err)
      console.log('err', err)
    }
  }

};




export default actions;
