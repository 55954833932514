import { getUser, createUser, updateUser } from "../../config/config";
//import { doInit } from 'actions/auth'
import { toast } from "react-toastify";
//import config from '../config'
//import { mockUser } from '../actions/mock'

export const USERS_FORM_RESET = "USERS_FORM_RESET";
export const USERS_FORM_FIND_SUCCESS = "USERS_FORM_FIND_SUCCESS";
export const USERS_FORM_FIND_STARTED = "USERS_FORM_FIND_STARTED";
export const USERS_FORM_FIND_ERROR = "USERS_FORM_FIND_ERROR";
export const USERS_FORM_CREATE_STARTED = "USERS_FORM_CREATE_STARTED";
export const USERS_FORM_CREATE_SUCCESS = "USERS_FORM_CREATE_SUCCESS";
export const USERS_FORM_CREATE_ERROR = "USERS_FORM_CREATE_ERROR";
export const USERS_FORM_UPDATE_STARTED = "USERS_FORM_UPDATE_STARTED";
export const USERS_FORM_UPDATE_SUCCESS = "USERS_FORM_UPDATE_SUCCESS";
export const USERS_FORM_UPDATE_ERROR = "USERS_FORM_UPDATE_ERROR";
export const USERS_PASSWORD_UPDATE_SUCCESS = "USERS_PASSWORD_UPDATE_SUCCESS";

export const UPDATE_PAUSE_ALL = "UPDATE_PAUSE_ALL";
export const UPDATE_PAUSE_ALL_FALSE = "UPDATE_PAUSE_ALL_FALSE";
export const UPDATE_PAUSE_MONDAY = "UPDATE_PAUSE_MONDAY";
export const UPDATE_PAUSE_TUESDAY = "UPDATE_PAUSE_TUESDAY";
export const UPDATE_PAUSE_WEDNESDAY = "UPDATE_PAUSE_WEDNESDAY";
export const UPDATE_PAUSE_THURSDAY = "UPDATE_PAUSE_THURSDAY";
export const UPDATE_PAUSE_FRIDAY = "UPDATE_PAUSE_FRIDAY";
export const UPDATE_PAUSE_SATURDAY = "UPDATE_PAUSE_SATURDAY";
export const UPDATE_PAUSE_SUNDAY = "UPDATE_PAUSE_SUNDAY";
export const UPDATE_INCORRECT_EMAIL = "UPDATE_INCORRECT_EMAIL";

/* modify the Activity per user */
/*const USERS_ACTIVITY_FETCH_STARTED = 'USERS_ACTIVITY_FETCH_STARTED';
const USERS_ACTIVITY_FETCH_SUCCESS = 'USERS_ACTIVITY_FETCH_SUCCESS';
const USERS_ACTIVITY_FETCH_ERROR = 'USERS_ACTIVITY_FETCH_ERROR';*/

function selectErrorMessage(error) {
  if (error && error.response && error.response.data) {
    const data = error.response.data;

    if (data.error && data.error.message) {
      return data.error.message;
    }

    return JSON.stringify(data.message);
  }

  return error.message || "ERROR";
}

const actions = {
  doNew: () => {
    return {
      type: USERS_FORM_RESET,
    };
  },

  doFind: (id, history) => async (dispatch) => {
    try {
      dispatch({
        type: USERS_FORM_FIND_STARTED,
      });

      await getUser({ client_id: id }).then((res) => {
        const record = res.data[0];
        let sunday = false;
        let monday = false;
        let tuesday = false;
        let wednesday = false;
        let thursday = false;
        let friday = false;
        let saturday = false;

        let incorrect;
        if (res.data[0].incorrect_email === 0) {
          incorrect = false;
        } else {
          incorrect = true;
        }

        if (res.data[0]?.client_pause_email) {
          let arr = JSON.parse(res.data[0].client_pause_email);
          Promise.all(
            arr.map((zone) => {
              if (zone === 0) {
                sunday = true;
              }
              if (zone === 1) {
                monday = true;
              }
              if (zone === 2) {
                tuesday = true;
              }
              if (zone === 3) {
                wednesday = true;
              }
              if (zone === 4) {
                thursday = true;
              }
              if (zone === 5) {
                friday = true;
              }
              if (zone === 6) {
                saturday = true;
              }
            })
          ).then(() => {
            dispatch({
              type: USERS_FORM_FIND_SUCCESS,
              payload: record,
              sunday: sunday,
              monday: monday,
              tuesday: tuesday,
              wednesday: wednesday,
              thursday: thursday,
              friday: friday,
              saturday: saturday,
              incorrectEmail: incorrect,
            });
          });
        } else {
          dispatch({
            type: USERS_FORM_FIND_SUCCESS,
            payload: record,
            sunday: false,
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            saturday: false,
            incorrectEmail: incorrect,
          });
        }
      });
    } catch (error) {
      toast.error(selectErrorMessage(error));
      dispatch({
        type: USERS_FORM_FIND_ERROR,
      });

      history.push("/admin/users");
    }
  },

  updateEmailPause: (param, check) => async (dispatch) => {
    if (param === "all") {
      dispatch({
        type: UPDATE_PAUSE_ALL,
      });
    } else if (param === "all false") {
      dispatch({
        type: UPDATE_PAUSE_ALL_FALSE,
      });
    } else if (param === "monday") {
      dispatch({
        type: UPDATE_PAUSE_MONDAY,
        payload: check,
      });
    } else if (param === "tuesday") {
      dispatch({
        type: UPDATE_PAUSE_TUESDAY,
        payload: check,
      });
    } else if (param === "wednesday") {
      dispatch({
        type: UPDATE_PAUSE_WEDNESDAY,
        payload: check,
      });
    } else if (param === "thursday") {
      dispatch({
        type: UPDATE_PAUSE_THURSDAY,
        payload: check,
      });
    } else if (param === "friday") {
      dispatch({
        type: UPDATE_PAUSE_FRIDAY,
        payload: check,
      });
    } else if (param === "saturday") {
      dispatch({
        type: UPDATE_PAUSE_SATURDAY,
        payload: check,
      });
    } else if (param === "sunday") {
      dispatch({
        type: UPDATE_PAUSE_SUNDAY,
        payload: check,
      });
    }
  },

  updateIncorrectEmail: (check) => async (dispatch) => {
    dispatch({
      type: UPDATE_INCORRECT_EMAIL,
      payload: check,
    });
  },

  doCreate: (values, history) => async (dispatch) => {
    try {
      dispatch({
        type: USERS_FORM_CREATE_STARTED,
      });
      await createUser(values).then((res) => {
        dispatch({
          type: USERS_FORM_CREATE_SUCCESS,
        });
        toast.success("User created successfully");
        history.push("/admin/users");
      });
    } catch (error) {
      toast.error(selectErrorMessage(error));
      dispatch({
        type: USERS_FORM_CREATE_ERROR,
      });
    }
  },

  doUpdate:
    (id, values, history, emailPause, incorrectEmail) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: USERS_FORM_UPDATE_STARTED,
        });
        updateUser({
          client_id: id,
          data: values,
          emailPause: emailPause,
          incorrectEmail: incorrectEmail,
        }).then((res) => {
          dispatch({
            type: USERS_FORM_UPDATE_SUCCESS,
          });
          toast.success("User updated");
          history.push("/admin/users");
        });
      } catch (error) {
        toast.error(selectErrorMessage(error));

        dispatch({
          type: USERS_FORM_UPDATE_ERROR,
        });
      }
    },

  /*
    doChangePassword: ({newPassword, currentPassword}) => async (dispatch) => {
      try {
        dispatch({
          type: USERS_FORM_CREATE_STARTED,
        });
        await axios.put('/auth/password-update', {newPassword, currentPassword})
        dispatch({
          type: USERS_PASSWORD_UPDATE_SUCCESS,
        });
  
        toast.success('Password has been updated');
        dispatch(push('/app/main'));
  
      } catch (error) {
        Errors.handle(error);
  
        dispatch({
          type: USERS_FORM_CREATE_ERROR,
        });
      }
    },*/
};

export default actions;
