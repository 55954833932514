import {
  Transaction_FETCHED_STARTED,
  Transaction_FETCHED_SUCCESS,
  Transaction_FETCHED_ERROR,
} from "../actions/getSubscriptionTransactions";

const defaultState = {
  Transactions: [],
  loading: false,
};

function getSubscriptionTransactionsReducer(state = defaultState, action) {
  switch (action.type) {
    case Transaction_FETCHED_STARTED:
      return {
        ...state,
        loading: true,
      };
    case Transaction_FETCHED_SUCCESS:
      return {
        ...state,
        loading: false,
        Transactions: action.payload.data,
      };
    case Transaction_FETCHED_ERROR:
      return {
        ...state,
        loading: false,
        Transactions: [],
      };
    default:
      return state;
  }
}

export default getSubscriptionTransactionsReducer;
