import React, { lazy } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Hammer from "rc-hammerjs";
import BreadcrumbHistory from "../BreadcrumbHistory";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { Switch, Route, Redirect } from "react-router";

/* REDUX */
import { openSidebar, closeSidebar } from "../../redux/actions/navigation";

/* COMPONENTS */
import Header from "../Header";
import Sidebar from "../Sidebar";

/* CSS */
import s from "./Layout.module.scss";


/* PAGES */
const Dashboard = lazy(() => import("../../pages/dashboard"));
const DashboardAnalytics = lazy(() =>
  import("../../pages/analytics/Analytics")
);
const ActiveTable = lazy(() => import("../../pages/analytics/onlineNow"));

const HorseProfile = lazy(() => import("../../pages/analytics/HorseProfile"));
const Subscription = lazy(() => import("../../pages/subscription/Subscription"));
const SubscriptionPlans = lazy(() => import("../../pages/subscription/SubscriptionPlans"));
const SubscriptionsTrails = lazy(() => import("../../pages/subscription/SubscriptionsTrails"));

const Transactions = lazy(() => import("../../pages/transactions/Transactions"));
const Marketing = lazy(() => import("../../pages/marketing/Marketing"));
const RaceHistoryAnalytics = lazy(() =>
  import("../../pages/dashboard/RaceHistoryFilter")
);
const RodRaceHistoryAnalytics = lazy(() =>
  import("../../pages/dashboard/rodRaceFilter/RodRaceHistoryFilter")
);
const TrackPer = lazy(() =>
  import("../../pages/dashboard/rodRaceFilter/components/TrackPer")
);
const UserListPage = lazy(() => import("../../pages/users/list/UsersListPage"));
const UserDatePage = lazy(() =>
  import("../../pages/users/registeredDate/UsersDatePage")
);
const UserFormPage = lazy(() => import("../../pages/users/form/UsersFormPage"));
const UserViewPage = lazy(() => import("../../pages/users/view/UsersViewPage"));
const UserActivityPage = lazy(() =>
  import("../../pages/users/activity/UsersActivityPage")
);
const UserFrequencyPage = lazy(() =>
  import("../../pages/users/frequency/UsersFrequencyPage")
);
const DailyFrequencyPage = lazy(() =>
  import("../../pages/users/frequency/DailyFrequencyPage")
);

const FileLoaderPage = lazy(() =>
  import("../../pages/selections/FileLoaderPage")
);
const FormingPage = lazy(() =>
  import("../../pages/selections/forming/FormingPage")
);
const FormingCustPage = lazy(() =>
  import("../../pages/selections/forming-cust/FormingPage")
);
const SelectionGenerationPage = lazy(() =>
  import("../../pages/selections/sele-generation/SelectionGenerationPage")
);
const SelectionResultPage = lazy(() =>
  import("../../pages/selections/sele-results/SelResultsPage")
);
const RaceAnalysisPage = lazy(() =>
  import("../../pages/selections/race-analysis/RaceAnalysisPage")
);
const FiveStarsSelectionPage = lazy(() =>
  import(
    "../../pages/selections/fivestars-sele-generation/FiveStarsSelectionPage"
  )
);
const RailPosPage = lazy(() => import("../../pages/railpos/RailPosPage"));
const SpeedMapPage = lazy(() =>
  import("../../pages/selections/speed-map/SpeedMapPage")
);
const MapsComparisonPage = lazy(() =>
  import("../../pages/selections/maps-comparaison/MapsComparisonPage")
);
const JockeyPremiershipPage = lazy(() =>
  import("../../pages/jockeyPremiership/JockeyPremiershipPage")
);
const JockeyPremiershipFormPage = lazy(() =>
  import("../../pages/jockeyPremiership/form/JockeyPremierShipFormPage")
);
const Email = lazy(() => import("../../pages/email/Email"));
const onNow = lazy(() => import("../../pages/onNow/onNow"));

const AdminListPage = lazy(() =>
  import("../../pages/admins/list/AdminListPage")
);
const AdminViewPage = lazy(() =>
  import("../../pages/admins/view/AdminViewPage")
);
const AdminFormPage = lazy(() =>
  import("../../pages/admins/form/AdminFormPage")
);
const AnalyticsControlPage = lazy(() =>
  import("../../pages/admins/analyticsControl/AnalticsControlPage")
);

const APIPage = lazy(() => import("../../pages/api/Api"));
const VenuesPage = lazy(() => import("../../pages/venues/venuesPage"));
const VenuesEditInfo = lazy(() => import("../../pages/venues/venueEditInfo"));
const VenuesEditAllInfo = lazy(() => import("../../pages/venues/VenuesEditAllInfo"));
const BookmakerDataEntry = lazy(() => import("../../pages/bookmakers/BookmakerDataEntry"));
const BookmakersList = lazy(() => import("../../pages/bookmakers/BookmakersList"));
/******************************Smart Punting Systems*******************************/
const SPUsersListPage = lazy(() =>
  import("../../pagesSP/users/list/UsersListPage")
);
const SPUserFormPage = lazy(() =>
  import("../../pagesSP/users/form/UserFormPage")
);
const SPUserViewPage = lazy(() =>
  import("../../pagesSP/users/view/UsersViewPage")
);
const SPUserIpListPage = lazy(() =>
  import("../../pagesSP/users/userips/UsersIpListPage")
);
//const SPApiPage = lazy(() => import("../../pagesSP/apiController/ApiController"));

/******************************Leads*******************************/
const SPLeadsListPage = lazy(() =>
  import("../../pagesSP/Leads/list/LeadsListPage")
);
const SPLeadFormPage = lazy(() =>
  import("../../pagesSP/Leads/form/LeadFormPage")
);
const SPLeadViewPage = lazy(() =>
  import("../../pagesSP/Leads/view/LeadsViewPage")
);

const SPRacesListPage = lazy(() =>
  import("../../pagesSP/races/list/RacesListPage")
);
const SPRacesFormPage = lazy(() =>
  import("../../pagesSP/races/form/RaceFormPage")
);
const SPOddsCalculatorPage = lazy(() =>
  import("../../pagesSP/odds-calculator/OddsCalculatorPage")
);
const SPClientsOddsPage = lazy(() =>
  import("../../pagesSP/odds-calculator/clients-odds/ClientsOddsPage")
);
const SPCalculatorPage = lazy(() =>
  import("../../pagesSP/calculator/CalculatorPage")
);
const SPEmailUsers = lazy(() => import("../../pagesSP/email/Email"));
const SPEmailLeads = lazy(() => import("../../pagesSP/email/EmailLeads"));

const HomeTableListPage = lazy(() =>
  import("../../pagesSP/home-table/list/HomeTableListPage")
);
const HomeTableFormPage = lazy(() =>
  import("../../pagesSP/home-table/form/HomeTableFormPage")
);
const HomeTableViewPage = lazy(() =>
  import("../../pagesSP/home-table/view/HomeTableViewPage")
);

/******************************ADMIN SUPER USER*******************************/
const SUPERUser = lazy(() => import("../../pages/SuperUser/SuperUser"));
const HorseHistory = lazy(() =>
  import("../../pages/horseHistory/HorseHistoryLoader")
);

const venueDetails = lazy(() => import("../../pages/venues/VenueDetails"));

const AdvSelListPage = lazy(() =>
  import("../../pages/adv-sel/list/AdvSelListPage")
);
const AdvSelFormPage = lazy(() =>
  import("../../pages/adv-sel/form/AdvSelFormPage")
);
const AdvSelViewPage = lazy(() =>
  import("../../pages/adv-sel/view/AdvSelViewPage")
);

class Layout extends React.Component {
  static propTypes = {
    sidebarStatic: PropTypes.bool,
    sidebarOpened: PropTypes.bool,
    //dispatch: PropTypes.func.isRequired,
  };

  static defaultProps = {
    sidebarStatic: false,
    sidebarOpened: false,
  };

  handleSwipe = (e) => {
    if ("ontouchstart" in window) {
      if (e.direction === 4 && !this.state.chatOpen) {
        this.props.dispatch(openSidebar());
        return;
      }

      if (e.direction === 2 && this.props.sidebarOpened) {
        this.props.dispatch(closeSidebar());
        return;
      }

      this.setState({ chatOpen: e.direction === 2 });
    }
  };

  render() {
    return (
      <div
        className={[
          s.root,
          "sidebar-" + this.props.sidebarPosition,
          "sidebar-" + this.props.sidebarVisibility,
        ].join(" ")}>
        <div className={s.wrap}>
          <Header />
          <Sidebar />
          <Hammer onSwipe={this.handleSwipe}>
            <main className={s.content}>
              <BreadcrumbHistory url={this.props.location.pathname} />
              <TransitionGroup>
                <CSSTransition
                  key={this.props.location.key}
                  classNames="fade"
                  timeout={200}>
                  <div>
                    <Switch>
                      <Route
                        path="/admin"
                        exact
                        render={() => <Redirect to="/admin/analytics" />}
                      />
                      <Route
                        exact
                        path="/admin/dashboard"
                        component={Dashboard}
                      />
                      <Route
                        path="/admin/analytics"
                        exact
                        component={DashboardAnalytics}
                      />
                      <Route
                        path="/admin/analytics/onlineNow"
                        exact
                        component={ActiveTable}
                      />
                      <Route
                        path="/admin/analytics/onlineNow/:visit"
                        exact
                        component={ActiveTable}
                      />
                      <Route
                        path="/admin/racehistoryfilter"
                        exact
                        component={RaceHistoryAnalytics}
                      />
                      <Route
                        path="/admin/rodracehistoryfilter"
                        exact
                        component={TrackPer}
                      />
                      <Route
                        path="/admin/bookmaker-data-entry"
                        exact
                        component={BookmakerDataEntry}
                      />
                        <Route
                        path="/admin/bookmaker-data-entry/:id"
                        exact
                        component={BookmakerDataEntry}
                      />
                        <Route
                        path="/admin/bookmakers-list"
                        exact
                        component={BookmakersList}
                      />
                      <Route
                        path="/admin/subscription"
                        exact
                        component={Subscription}
                      />
                      <Route
                        path="/admin/transactions"
                        exact
                        component={Transactions}
                      />

<Route
                        path="/admin/plans"
                        exact
                        component={SubscriptionPlans}
                      />

<Route
                        path="/admin/users-trail"
                        exact
                        component={SubscriptionsTrails}
                      />


                      <Route
                        path="/admin/marketing"
                        exact
                        component={Marketing}
                      />
                      <Route
                        path="/admin/users"
                        exact
                        component={UserListPage}
                      />
                      <Route
                        path="/admin/users/registered/:date"
                        exact
                        component={UserDatePage}
                      />
                      <Route
                        path="/admin/users/new"
                        exact
                        component={UserFormPage}
                      />
                      <Route
                        path="/admin/users/:id/edit"
                        exact
                        component={UserFormPage}
                      />
                      <Route
                        path="/admin/users/:id"
                        exact
                        component={UserViewPage}
                      />
                      <Route
                        path="/admin/users_activity"
                        exact
                        component={UserActivityPage}
                      />
                      <Route
                        path="/admin/users_frequency"
                        exact
                        component={UserFrequencyPage}
                      />
                      <Route
                        path="/admin/daily_frequency"
                        exact
                        component={DailyFrequencyPage}
                      />
                      <Route
                        path="/admin/horseProfile"
                        exact
                        component={HorseProfile}
                      />

                      {/* ADMIN STAFF */}
                      <Route
                        path="/admin/staff_list"
                        exact
                        component={AdminListPage}
                      />
                      <Route
                        path="/admin/admins/:id"
                        exact
                        component={AdminViewPage}
                      />
                      <Route
                        path="/admin/admins"
                        exact
                        component={AdminFormPage}
                      />
                      <Route
                        path="/admin/admins/:id/edit"
                        exact
                        component={AdminFormPage}
                      />
                      <Route
                        path="/admin/staff_control"
                        exact
                        component={AnalyticsControlPage}
                      />
                      <Route path="/admin/email" exact component={Email} />

                      {/* LIVE API */}
                      <Route path="/admin/api" exact component={APIPage} />
                      <Route
                        path="/admin/venues"
                        exact
                        component={VenuesPage}
                      />
                      <Route
                        path="/admin/venueEdit"
                        exact
                        component={VenuesEditInfo}
                      />
                      <Route
                        path="/admin/venueEdit/:id"
                        exact
                        component={VenuesEditAllInfo}
                      />
                      <Route path="/admin/onNow" exact component={onNow} />

                      {/* SELECTIONS */}
                      <Route
                        path="/admin/upload_aap_forms"
                        exact
                        component={FileLoaderPage}
                      />
                      <Route
                        path="/admin/forming"
                        exact
                        component={FormingPage}
                      />
                      <Route
                        path="/admin/forming-cust"
                        exact
                        component={FormingCustPage}
                      />
                      <Route
                        path="/admin/selection-generation"
                        exact
                        component={SelectionGenerationPage}
                      />
                      <Route
                        path="/admin/selection-results"
                        exact
                        component={SelectionResultPage}
                      />
                      <Route
                        path="/admin/race-analysis"
                        exact
                        component={RaceAnalysisPage}
                      />
                      <Route
                        path="/admin/fivestars-sel"
                        exact
                        component={FiveStarsSelectionPage}
                      />
                      <Route
                        path="/admin/rail-pos"
                        exact
                        component={RailPosPage}
                      />
                      {/* SPEED MAP */}
                      <Route
                        path="/admin/speed-map"
                        exact
                        component={SpeedMapPage}
                      />
                      {/*Maps Comparison*/}
                      <Route
                        path={"/admin/mapsComparison"}
                        exact
                        component={MapsComparisonPage}
                      />

                      {/*Jockey Premiership*/}
                      <Route
                        path={"/admin/jockeyPremiership"}
                        exact
                        component={JockeyPremiershipPage}
                      />
                      <Route
                        path="/admin/jockeyPremiership/new"
                        exact
                        component={JockeyPremiershipFormPage}
                      />
                      <Route
                        path="/admin/jockeyPremiership/:id/edit"
                        exact
                        component={JockeyPremiershipFormPage}
                      />

                      {/* SMART PUNTING ADMIN API */}
                      <Route
                        path="/admin/smartpunting/users"
                        exact
                        component={SPUsersListPage}
                      />
                      <Route
                        path="/admin/smartpunting/userips"
                        exact
                        component={SPUserIpListPage}
                      />
                      <Route
                        path="/admin/smartpunting/users/new"
                        exact
                        component={SPUserFormPage}
                      />
                      <Route
                        path="/admin/smartpunting/users/:id/edit"
                        exact
                        component={SPUserFormPage}
                      />
                      <Route
                        path="/admin/smartpunting/users/:id"
                        exact
                        component={SPUserViewPage}
                      />
                      {/*<Route path="/admin/smartpunting/api" exact component={SPApiPage} />*/}

                      {/* SMART PUNTING ADMIN API */}
                      <Route
                        path="/admin/smartpunting/leads"
                        exact
                        component={SPLeadsListPage}
                      />
                      <Route
                        path="/admin/smartpunting/leads/new"
                        exact
                        component={SPLeadFormPage}
                      />
                      <Route
                        path="/admin/smartpunting/leads/:id/edit"
                        exact
                        component={SPLeadFormPage}
                      />
                      <Route
                        path="/admin/smartpunting/leads/:id"
                        exact
                        component={SPLeadViewPage}
                      />

                      <Route
                        path="/admin/smartpunting/hometable"
                        exact
                        component={HomeTableListPage}
                      />
                      <Route
                        path="/admin/smartpunting/hometable/new"
                        exact
                        component={HomeTableFormPage}
                      />
                      <Route
                        path="/admin/smartpunting/hometable/:id/edit"
                        exact
                        component={HomeTableFormPage}
                      />
                      <Route
                        path="/admin/smartpunting/hometable/:id"
                        exact
                        component={HomeTableViewPage}
                      />

                      <Route
                        path="/admin/smartpunting/races"
                        exact
                        component={SPRacesListPage}
                      />
                      <Route
                        path="/admin/smartpunting/races/new"
                        exact
                        component={SPRacesFormPage}
                      />
                      <Route
                        path="/admin/smartpunting/races/:id/edit"
                        exact
                        component={SPRacesFormPage}
                      />

                      <Route
                        path="/admin/smartpunting/oddscalculator"
                        exact
                        component={SPOddsCalculatorPage}
                      />
                      <Route
                        path="/admin/smartpunting/clientsodds"
                        exact
                        component={SPClientsOddsPage}
                      />
                      <Route
                        path="/admin/smartpunting/calculator"
                        exact
                        component={SPCalculatorPage}
                      />

                      {/*Email SP Users*/}
                      <Route
                        path={"/admin/smartpunting/emailUsers"}
                        exact
                        component={SPEmailUsers}
                      />

                      {/*Email SP Leads*/}
                      <Route
                        path={"/admin/smartpunting/emailLeads"}
                        exact
                        component={SPEmailLeads}
                      />

                      {/* ADMIN SUPER USER  */}
                      <Route
                        path="/admin/superUser"
                        exact
                        component={SUPERUser}
                      />
                      <Route
                        path="/admin/HorseHistory"
                        exact
                        component={HorseHistory}
                      />
                      <Route
                        path="/admin/venueDetails/:id"
                        exact
                        component={venueDetails}
                      />

                      {/* Advanced Selections  */}
                      <Route
                        path="/admin/adv-sel"
                        exact
                        component={AdvSelListPage}
                      />
                      <Route
                        path="/admin/adv-sel/new"
                        exact
                        component={AdvSelFormPage}
                      />
                      <Route
                        path="/admin/adv-sel/:id/edit"
                        exact
                        component={AdvSelFormPage}
                      />
                    </Switch>
                  </div>
                </CSSTransition>
              </TransitionGroup>
              <footer className={s.contentFooter}>
                Past The Post - Admin Portal
              </footer>
            </main>
          </Hammer>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  sidebarOpened: state.navigationReducer.sidebarOpened,
  sidebarPosition: state.navigationReducer.sidebarPosition,
  sidebarVisibility: state.navigationReducer.sidebarVisibility,
});

export default connect(mapStateToProps)(Layout);
