import { getSubscriptionPlans } from "../../config/config";

export const SUBSCRIPTION_PLANS_FETCHED_STARTED =
  "SUBSCRIPTION_PLANS_FETCHED_STARTED";
export const SUBSCRIPTION_PLANS_FETCHED_SUCCESS =
  "SUBSCRIPTION_PLANS_FETCHED_SUCCESS";
export const SUBSCRIPTION_PLANS_FETCHED_ERROR =
  "SUBSCRIPTION_PLANS_FETCHED_ERROR";

const subscriptionPlanActions = {
  getSubscriptionPlans: () => async (dispatch) => {
    try {
      dispatch({ type: SUBSCRIPTION_PLANS_FETCHED_STARTED });
      const res = await getSubscriptionPlans();

      if (res) {
        dispatch({ type: SUBSCRIPTION_PLANS_FETCHED_SUCCESS, payload: res });
      } else {
        dispatch({ type: SUBSCRIPTION_PLANS_FETCHED_ERROR });
      }
      return res;
    } catch (error) {
      dispatch({ type: SUBSCRIPTION_PLANS_FETCHED_ERROR });
    }
  },
};

export default subscriptionPlanActions;
