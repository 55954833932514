import {
  SUBSCRIPTION_PLANS_FETCHED_STARTED,
  SUBSCRIPTION_PLANS_FETCHED_SUCCESS,
  SUBSCRIPTION_PLANS_FETCHED_ERROR,
} from "../actions/getSubscriptionPlans";

const defaultState = {
  Plans: [],
  loading: false,
};

function getSubscriptionPlansReducer(state = defaultState, action) {
  switch (action.type) {
    case SUBSCRIPTION_PLANS_FETCHED_STARTED:
      return {
        ...state,
        loading: true,
      };
    case SUBSCRIPTION_PLANS_FETCHED_SUCCESS:
      return {
        ...state,
        loading: false,
        Plans: action.payload.data,
      };
    case SUBSCRIPTION_PLANS_FETCHED_ERROR:
      return {
        ...state,
        loading: false,
        Plans: [],
      };
    default:
      return state;
  }
}

export default getSubscriptionPlansReducer;
