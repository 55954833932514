import {
  updatetrail_FETCH_STARTED ,
  updatetrail_FETCH_SUCCESS ,
  updatetrail_FETCH_ERROR 
} from "../actions/updateTrials";

 


const defaultState = {
 updatedTrials: [],
  loading: false,
};

function updatetrialReducer(state = defaultState, action) {
  switch (action.type) {
    case updatetrail_FETCH_STARTED:
      return Object.assign({}, state, {
        ...state,
        loading: true,
      });
    case updatetrail_FETCH_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        updatedTrials: action.payload.data,
      });
    case updatetrail_FETCH_ERROR:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        updatedTrials: [],
      });
    default:
      return state;
  }
}

export default updatetrialReducer;
