import {
  saveNewBookmakerAPIService, fetchBookmakersListAPIService,
  deleteBookmakerAPIService, updateBookmakerAPIService, updateBookmakerActiveStatus
} from '../../config/config'
import { toast } from 'react-toastify'

export const BOOK_MAKER_SAVE_START = 'BOOK_MAKER_SAVE_START';
export const BOOK_MAKER_SAVE_SUCCESS = 'BOOK_MAKER_SAVE_SUCCESS';
export const BOOK_MAKER_SAVE_ERROR = 'BOOK_MAKER_SAVE_ERROR';

export const BOOK_MAKER_UPDATE_START = 'BOOK_MAKER_UPDATE_START';
export const BOOK_MAKER_UPDATE_SUCCESS = 'BOOK_MAKER_UPDATE_SUCCESS';
export const BOOK_MAKER_UPDATE_ERROR = 'BOOK_MAKER_UPDATE_ERROR';

export const BOOK_MAKERS_FETCH_START = 'BOOK_MAKERS_FETCH_START';
export const BOOK_MAKER_FETCH_SUCCESS = 'BOOK_MAKER_FETCH_SUCCESS';
export const BOOK_MAKER_FETCH_ERROR = 'BOOK_MAKER_FETCH_ERROR';

export const BOOK_MAKER_DELETE_START = 'BOOK_MAKER_DELETE_START';
export const BOOK_MAKER_DELETE_SUCCESS = 'BOOK_MAKER_DELETE_SUCCESS';
export const BOOK_MAKER_DELETE_ERROR = 'BOOK_MAKER_DELETE_ERROR';



const bookmakerObj = async (rows) => {
  let newArr = [];
  await rows.map((item) => {
    let obj = {
      id: item?.id,
      basicInfo: JSON.parse(item?.basicInfo),
      rating: item?.rating,
      isActive: item?.isActive,
      shortDescription: item?.shortDescription,
      expertReview: item?.expertReview,
      expertReviewImgUrl: item?.expertReviewImgUrl,

      website: JSON.parse(item?.web_site),
      mobileApps: JSON.parse(item?.mobile_Apps),
      signUpBonus: item?.signUpBonus,
      signUpBonusImgUrl: item?.signUpBonusImgUrl,
      salientFeatures: JSON.parse(item?.salient_features),
      pros: JSON.parse(item?.pros_bs),
      cons: JSON.parse(item?.cons_bs),
      contactSupport: JSON.parse(item?.contact_support),
      licenseAndSecurity: JSON.parse(item?.license_security),
      registrationProcess: JSON.parse(item?.registration_process),
      verificationProcess: JSON.parse(item?.verification_process),
      paymentMethods: JSON.parse(item?.payment_methods),

      faqs: JSON.parse(item?.faqs),
      softwareProviders: JSON.parse(item?.softwareProviders),
      finalVerdict: item?.finalVerdict,
      contentTags: item?.contentTags,
      betTypes: JSON.parse(item?.betTypes),
      betDefinitions: JSON.parse(item?.betDefinitions),
      odds: item?.odds,
      bestOfBest: item?.bestOfBest,
      topFluc: item?.topFluc,
      matchUp: item?.matchUp,
      partnershipsAndSponsorships: JSON.parse(item?.partnershipsAndSponsorships),
      betting: JSON.parse(item?.betting),
      marketsAvailable: JSON.parse(item?.marketsAvailable),
      productsSportsCoverage: JSON.parse(item?.productsSportsCoverage),
      racing: JSON.parse(item?.racing),
      liveInPlayBet: JSON.parse(item?.liveInPlayBet),
      bettingExchange: JSON.parse(item?.bettingExchange),
      wageringOptions: JSON.parse(item?.wageringOptions),
      servicesOptions: JSON.parse(item?.servicesOptions),

      phoneBetting: JSON.parse(item?.phoneBetting),
      disputeHandling: JSON.parse(item?.disputeHandling),
      gamesAndCasino: JSON.parse(item?.gamesAndCasino),
      oddsImgUrl: item?.oddsImgUrl,
      history: item?.history,
    }
    newArr.push(obj)
  })

  return newArr;
}

// save bookmaker data
const ACTIONS = {
  saveNewBookmaker: (body) => async (dispatch) => {
    try {
      dispatch({ type: BOOK_MAKER_SAVE_START });
      const res = await saveNewBookmakerAPIService(body);
      if (res.status === 200) {
        toast.success("Bookmaker Saved");
        const rows = await bookmakerObj(res.data.data);
        dispatch({ type: BOOK_MAKER_SAVE_SUCCESS, payload: { data: rows }, });
      } else {
        dispatch({ type: BOOK_MAKER_SAVE_ERROR });
        toast.error("Error in saving Bookmaker Data");
      }
      return res.data;
    } catch (error) {
      toast.error('Error in saving Bookmaker Data' + error);
      dispatch({ type: BOOK_MAKER_SAVE_ERROR });
    }
  },
  // update bookmaker data
  updateBookmaker: (body) => async (dispatch) => {
    const depositOptions = body.paymentMethods.depositOptions.map(v => ({
      ...v,
      active: (typeof v.active === 'boolean' ? v.active : (v?.active === 'true'))
    }))
    const withdrawalOptions = body.paymentMethods.withdrawalOptions.map(v => ({
      ...v,
      active: (typeof v.active === 'boolean' ? v.active : (v?.active === 'true'))
    }))
    let newObj = body;
    newObj.paymentMethods.depositOptions = depositOptions;
    newObj.paymentMethods.withdrawalOptions = withdrawalOptions;

    try {
      dispatch({ type: BOOK_MAKER_UPDATE_START });
      const res = await updateBookmakerAPIService(body);
      if (res.status === 200) {
        toast.success("Bookmaker Updated");
        const rows = await bookmakerObj(res.data.data);
        dispatch({ type: BOOK_MAKER_UPDATE_SUCCESS, payload: { data: rows }, });
      } else {
        dispatch({ type: BOOK_MAKER_UPDATE_ERROR });
        toast.error("Error in updating Bookmaker Data");
      }
      return res.data;
    } catch (error) {
      toast.error('Error in updating Bookmaker Data' + error);
      dispatch({ type: BOOK_MAKER_UPDATE_ERROR });
    }
  },


  // toggle bookmaker active status 
  toggleActiveBookmaker: (body) => async (dispatch) => {
    try {
      dispatch({ type: BOOK_MAKER_UPDATE_START });
      const res = await updateBookmakerActiveStatus(body);
      if (res.status === 200) {
        toast.success("Bookmaker Updated");
        const rows = await bookmakerObj(res.data.data);
        dispatch({ type: BOOK_MAKER_UPDATE_SUCCESS, payload: { data: rows }, });
      } else {
        dispatch({ type: BOOK_MAKER_UPDATE_ERROR });
        toast.error("Error in updating Bookmaker Data");
      }
      return res.data;
    } catch (error) {
      toast.error('Error in updating Bookmaker Data' + error);
      dispatch({ type: BOOK_MAKER_UPDATE_ERROR });
    }
  },

  // fetch bookmaker list
  fetchBookmakersList: (body) => async (dispatch) => {
    try {
      dispatch({ type: BOOK_MAKERS_FETCH_START });
      const res = await fetchBookmakersListAPIService(body);
      if (res.status === 200) {
        const rows = await bookmakerObj(res.data.data);
        dispatch({ type: BOOK_MAKER_FETCH_SUCCESS, payload: { data: rows }, });
      } else {
        dispatch({ type: BOOK_MAKER_FETCH_ERROR });
        toast.error("Error in fetching Bookmaker List");
      }
      return res.data;
    } catch (error) {
      dispatch({ type: BOOK_MAKER_FETCH_ERROR });
      toast.error('Error in fetching Bookmaker List' + error);
    }
  },

  // fetch bookmaker 
  deleteBookmaker: (id, BookMakerArr) => async (dispatch) => {
    try {
      dispatch({ type: BOOK_MAKER_DELETE_START });
      const res = await deleteBookmakerAPIService(id);
      if (res.status === 200) {
        if (res.data.status) {
          toast.success("Bookmaker Deleted");
          const newArr = await BookMakerArr.filter(x => { return x.id !== id });
          dispatch({ type: BOOK_MAKER_DELETE_SUCCESS, payload: { data: newArr } });
        } else {
          toast.success("Invalid Bookmaker");
        }
      } else {
        dispatch({ type: BOOK_MAKER_DELETE_ERROR });
        toast.error("Error in deleting Bookmaker");
      }
      return res.data;
    } catch (error) {
      dispatch({ type: BOOK_MAKER_DELETE_ERROR });
      toast.error('Error in deleting Bookmaker' + error);
    }
  },
};




export default ACTIONS;
