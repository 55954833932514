import {SUBSCRIPTION_USERS_FETCHED_STARTED,SUBSCRIPTION_USERS_FETCHED_SUCCESS , SUBSCRIPTION_USERS_FETCHED_ERROR}  from "../actions/getSubscriptionUser";

const defaultState= {
  Users : [],
  loading:false
}


function getSubscriptionUsersReducer(state = defaultState, action) {

  switch (action.type) {
    case SUBSCRIPTION_USERS_FETCHED_STARTED:
      return   {
        ...state,
        loading: true,
      };
    case SUBSCRIPTION_USERS_FETCHED_SUCCESS:
      return   {
        ...state,
        loading: false,
        Users: action.payload.data,
      };
    case SUBSCRIPTION_USERS_FETCHED_ERROR:
      return  {
        ...state,
        loading: false,
        Users: [],
      };
    default:
      return state;
  }
}

export default getSubscriptionUsersReducer;