import { getAllTrials } from "../../config/config";

export const AllTrials_FETCHED_STARTED =
  "AllTrials_FETCHED_STARTED";
export const AllTrials_FETCHED_SUCCESS =
  "AllTrials_FETCHED_SUCCESS";
export const AllTrials_FETCHED_ERROR =
  "AllTrials_FETCHED_ERROR";

const getAllTrialsActions = {
  getAllTrials: () => async (dispatch) => {
    try {
      dispatch({ type: AllTrials_FETCHED_STARTED });
      const res = await getAllTrials();
      console.log("actionres:",res)

      if (res) {
        dispatch({ type: AllTrials_FETCHED_SUCCESS, payload: res });
      } else {
        dispatch({ type: AllTrials_FETCHED_ERROR });
      }
      return res;
    } catch (error) {
      dispatch({ type: AllTrials_FETCHED_ERROR });
    }
  },
};

export default getAllTrialsActions;
