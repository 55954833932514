import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import s from "./Sidebar.module.scss";
import LinksGroup from "./LinksGroup/LinksGroup";

import { changeActiveSidebarItem } from "../../redux/actions/navigation";
//import { logoutUser } from '../../actions/auth';

/* IMAGE */
import PTP from "../../styles/images/ptp-logo.png";

class Sidebar extends React.Component {
  static propTypes = {
    sidebarStatic: PropTypes.bool,
    sidebarOpened: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    activeItem: PropTypes.string,
  };

  static defaultProps = {
    sidebarStatic: false,
    activeItem: "",
  };

  componentDidMount() {
    this.element.addEventListener(
      "transitionend",
      () => {
        if (this.props.sidebarOpened) {
          this.element.classList.add(s.sidebarOpen);
        }
      },
      false
    );
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.sidebarOpened !== this.props.sidebarOpened) {
      if (nextProps.sidebarOpened) {
        this.element.style.height = `${this.element.scrollHeight}px`;
      } else {
        this.element.classList.remove(s.sidebarOpen);
        setTimeout(() => {
          this.element.style.height = "";
        }, 0);
      }
    }
  }

  render() {
    let user = JSON.parse(this.props.currentUser);

    return (
      <nav
        className={cx(s.root)}
        ref={(nav) => {
          this.element = nav;
        }}
      >
        <header className={s.logo}>
          <a href="https://www.ptptips.com.au">
            <img src={PTP} alt="ptp logo" className={s.logo} />
          </a>
        </header>

        <ul className={s.nav}>
          <LinksGroup
            onActiveSidebarItemChange={(activeItem) =>
              this.props.dispatch(changeActiveSidebarItem(activeItem))
            }
            activeItem={this.props.activeItem}
            header="Dashboard"
            isHeader
            iconName="flaticon-home"
            link="/admin"
            index="admin"
            childrenLinks={[
              {
                header: "User Analytics",
                link: "/admin/analytics",
              },
              user.role === 1
                ? {
                    header: "Races Analytics",
                    link: "/admin/dashboard",
                  }
                : +null,
              user.role === 1
                ? {
                    header: "Horses Profile",
                    link: "/admin/horseProfile",
                  }
                : +null,
              user.role === 1
                ? {
                    header: "Marketing",
                    link: "/admin/marketing",
                  }
                : +null,
              user.role === 1
                ? {
                    header: "Race Filter",
                    link: "/admin/racehistoryfilter",
                  }
                : +null,
              user.role === 1
                ? {
                    header: "Rod Race Filter",
                    link: "/admin/rodracehistoryfilter",
                  }
                : +null,
              user.role === 1
                ? {
                    header: "Bookmaker List",
                    link: "/admin/bookmakers-list",
                  }
                : +null,
              user.role === 1
                ? {
                    header: "Subscriptions",
                    link: "/admin/subscription",
                  }
                : +null,
              user.role === 1
                ? {
                    header: "Transaction",
                    link: "/admin/transactions",
                  }
                : +null,
              user.role === 1
                ? {
                    header: "User-Trails",
                    link: "/admin/users-trail",
                  }
                : +null,
            ]}
          />
          <LinksGroup
            onActiveSidebarItemChange={(activeItem) =>
              this.props.dispatch(changeActiveSidebarItem(activeItem))
            }
            activeItem={this.props.activeItem}
            header="PTP TIPS"
            isHeader
            labelColor="danger"
            iconName="flaticon-play-button"
            link="/PTP"
            index="PTP"
            label="***"
            childrenLinks={[
              user.role === 1 || user.role === 3 || user.role === 2
                ? {
                    header: "Rail Position",
                    link: "/admin/rail-pos",
                    index: "RailPosition",
                  }
                : +null,
              user.role === 1 || user.role === 3
                ? {
                    header: "Jockey Premiership",
                    link: "/admin/jockeyPremiership",
                    index: "JockeyPremiership",
                  }
                : +null,
              user.role === 1 || user.role === 3 || user.role === 2
                ? {
                    header: "Email",
                    link: "/admin/email",
                    index: "Email",
                  }
                : +null,
              user.role === 1 || user.role === 2 || user.role === 3
                ? {
                    header: "Users",
                    link: "/PTPUsers",
                    index: "PTPUsers",
                    childrenLinks: [
                      {
                        header: "List",
                        link: "/admin/users",
                        index: "Users_List",
                      },
                      {
                        header: "Add User",
                        link: "/admin/users/new",
                        index: "Add_User",
                      },
                    ],
                  }
                : +null,
              user.role === 1 || user.role === 3
                ? {
                    header: "Activity",
                    link: "/PTPActivity",
                    index: "PTPActivity",
                    childrenLinks: [
                      {
                        header: "All Users",
                        link: "/admin/users_activity",
                        index: "Users_Activity",
                      },
                      {
                        header: "User Frequency",
                        link: "/admin/users_frequency",
                        index: "Users_Frequency",
                      },
                      {
                        header: "Daily Frequency",
                        link: "/admin/daily_frequency",
                        index: "Daily_Frequency",
                      },
                      {
                        header: "Active Now",
                        link: "/admin/active_now",
                        index: "Active_Now",
                      },
                    ],
                  }
                : +null,
              user.role === 1
                ? {
                    header: "Staff",
                    link: "/PTPStaff",
                    index: "PTPStaff",
                    childrenLinks: [
                      {
                        header: "Admin List",
                        link: "/admin/staff_list",
                        index: "Staff_List",
                      },
                      {
                        header: "Add Admin",
                        link: "/admin/admins",
                        index: "Add_Admin",
                      },
                      {
                        header: "Analytics Control",
                        link: "/admin/staff_control",
                        index: "Staff_Control",
                      },
                    ],
                  }
                : +null,
              user.role === 1 || user.role === 2
                ? {
                    header: "SELECTIONS",
                    link: "/PTPSelection",
                    index: "PTPSelection",
                    childrenLinks: [
                      {
                        header: "Upload AAP Forms",
                        link: "/admin/upload_aap_forms",
                      },
                      {
                        header: "Forming",
                        link: "/admin/forming",
                      },
                      {
                        header: "Forming-Cust",
                        link: "/admin/forming-cust",
                      },
                      {
                        header: "Selection Generation",
                        link: "/admin/selection-generation",
                      },
                      {
                        header: "Selection Results",
                        link: "/admin/selection-results",
                      },
                      {
                        header: "Race Analysis",
                        link: "/admin/race-analysis",
                      },
                      {
                        header: "Speed Map",
                        link: "/admin/speed-map",
                      },
                      {
                        header: "Maps comparison",
                        link: "/admin/mapsComparison",
                      },
                    ],
                  }
                : +null,
              user.role === 1 || user.role === 2
                ? {
                    header: "PTPSELECTION2",
                    link: "/PTPSelection2",
                    index: "PTPSelection2",
                    childrenLinks: [
                      {
                        // header: 'Race Analysis', link: '/admin/race-analysis',
                      },
                      {
                        header: "PTP Selections2",
                        link: "/admin/fivestars-sel",
                      },
                    ],
                  }
                : +null,
              user.role === 1 || user.role === 2
                ? {
                    header: "ADV SEL.",
                    link: "/PTPADSelection",
                    index: "PTPADSelection",
                    childrenLinks: [
                      {
                        header: "List",
                        link: "/admin/adv-sel",
                        index: "Adv Sel List",
                      },
                      {
                        header: "Add",
                        link: "/admin/adv-sel/new",
                        index: "ADV_SEL",
                      },
                    ],
                  }
                : +null,
              user.role === 1 || user.role === 2
                ? {
                    header: "Controller",
                    link: "/PTPController",
                    index: "PTPController",
                    childrenLinks: [
                      {
                        header: "Bookies API",
                        link: "/admin/api",
                      },
                      {
                        header: "Venues",
                        link: "/admin/venues",
                      },
                    ],
                  }
                : +null,
              user.role === 1 || user.role === 2 || user.role === 3
                ? {
                    header: "On Now",
                    link: "/admin/onNow",
                    index: "onNow",
                  }
                : +null,
              user.role === 1 || user.role === 2 || user.role === 3
                ? {
                    header: "Horse History",
                    link: "/admin/HorseHistory",
                    index: "Horse History",
                  }
                : +null,
              user.id === 13 || user.id === 1
                ? {
                    header: "Super User",
                    link: "/admin/superUser",
                    index: "SuperUser",
                  }
                : +null,
            ]}
          />

          <LinksGroup
            onActiveSidebarItemChange={(activeItem) =>
              this.props.dispatch(changeActiveSidebarItem(activeItem))
            }
            activeItem={this.props.activeItem}
            header="Smart Punting"
            isHeader
            labelColor="orange"
            iconName="flaticon-play-button"
            link="/SPS"
            index="SPS"
            label="***"
            childrenLinks={[
              user.role === 1 || user.role === 2 || user.role === 4
                ? {
                    header: "Users",
                    link: "/SPSUsers",
                    index: "SPSUsers",
                    childrenLinks: [
                      {
                        header: "List",
                        link: "/admin/smartpunting/users",
                        index: "SPS_Users_List",
                      },
                      {
                        header: "Add User",
                        link: "/admin/smartpunting/users/new",
                        index: "SPS_Add_User",
                      },
                      {
                        header: "Send Messages",
                        link: "/admin/smartpunting/emailUsers",
                        index: "SPS_Email_Users",
                      },
                      {
                        header: "IP Hunting",
                        link: "/admin/smartpunting/userips",
                        index: "SPS_Users_List_Ips",
                      },
                    ],
                  }
                : +null,
              user.role === 1
                ? {
                    header: "Leads",
                    link: "/SPSLeads",
                    index: "SPSLeads",
                    childrenLinks: [
                      {
                        header: "List",
                        link: "/admin/smartpunting/Leads",
                        index: "SPS_Leads_List",
                      },
                    ],
                  }
                : +null,
              user.role === 1 || user.role === 2 || user.role === 4
                ? {
                    header: "Races",
                    link: "/SPSRaces",
                    index: "SPSRaces",
                    childrenLinks: [
                      {
                        header: "List",
                        link: "/admin/smartpunting/races",
                        index: "SPS_RACES_LIST",
                      },
                      {
                        header: "Add Race",
                        link: "/admin/smartpunting/races/new",
                        index: "SPS_ADD_Race",
                      },
                    ],
                  }
                : +null,
              user.role === 1 || user.role === 2 || user.role === 4
                ? {
                    header: "Odds Calculator",
                    link: "/SPSCalc",
                    index: "SPSCalc",
                    childrenLinks: [
                      {
                        header: "Odds Calc",
                        link: "/admin/smartpunting/oddscalculator",
                        index: "SPS_ODDS_CALCULATOR",
                      },
                      {
                        header: "Clients Odds",
                        link: "/admin/smartpunting/clientsodds",
                        index: "SPS_ODDS_CLIENTS",
                      },
                      {
                        header: "Calculator",
                        link: "/admin/smartpunting/calculator",
                        index: "SPS_CALCULATOR",
                      },
                      {
                        header: "Custom Message",
                        link: "/admin/smartpunting/emailUsers",
                        index: "SPS_CUST_EMAIL",
                      },
                    ],
                  }
                : +null,
              user.role === 1 || user.role === 2 || user.role === 4
                ? {
                    header: "Home Table",
                    link: "/SPSHomeTable",
                    index: "SPSHomeTable",
                    childrenLinks: [
                      {
                        header: "List",
                        link: "/admin/smartpunting/hometable",
                        index: "SPS_HOME_TABLE",
                      },
                      {
                        header: "Add",
                        link: "/admin/smartpunting/hometable/new",
                        index: "SPS_HOME_TABLE_NEW",
                      },
                    ],
                  }
                : +null,
              /*{
                header: 'Api Controller', 
                link: '/admin/smartpunting/api',
                index: 'SPApi',
              }*/
            ]}
          />
        </ul>
      </nav>
    );
  }
}

const mapStateToProps = (state) => ({
  sidebarOpened: state.navigationReducer.sidebarOpened,
  sidebarStatic: state.navigationReducer.sidebarStatic,
  activeItem: state.navigationReducer.activeItem,
  currentUser: state.auth.currentUser,
});

export default connect(mapStateToProps)(Sidebar);
