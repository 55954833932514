import {
  AllTrials_FETCHED_STARTED,
  AllTrials_FETCHED_SUCCESS,
  AllTrials_FETCHED_ERROR,
} from "../actions/getAllTrials";

const defaultState = {
  Trails: [],
  loading: false,
};

function getAllTrialsReducer(state = defaultState, action) {
  switch (action.type) {
    case AllTrials_FETCHED_STARTED:
      return {
        ...state,
        loading: true,
      };
    case AllTrials_FETCHED_SUCCESS:
      return {
        ...state,
        loading: false,
        Transactions: action.payload.data,
      };
    case AllTrials_FETCHED_ERROR:
      return {
        ...state,
        loading: false,
        Transactions: [],
      };
    default:
      return state;
  }
}

export default getAllTrialsReducer;
