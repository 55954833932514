import { getSubscriptionUsers } from "../../config/config";

export const SUBSCRIPTION_USERS_FETCHED_STARTED =
  "SUBSCRIPTION_USERS_FETCHED_STARTED";
export const SUBSCRIPTION_USERS_FETCHED_SUCCESS =
  "SUBSCRIPTION_USERS_FETCHED_SUCCESS";
export const SUBSCRIPTION_USERS_FETCHED_ERROR =
  "SUBSCRIPTION_USERS_FETCHED_ERROR";

const subscriptionUsersActions = {
  getSubscriptionUsers: () => async (dispatch) => {
    try {
      dispatch({ type: SUBSCRIPTION_USERS_FETCHED_STARTED });
      const res = await getSubscriptionUsers();
      
      if (res) {
        
        dispatch({ type: SUBSCRIPTION_USERS_FETCHED_SUCCESS, payload: res });
      } else {
        dispatch({ type: SUBSCRIPTION_USERS_FETCHED_ERROR });
       
      }
      return res;
    } catch (error) {
      dispatch({ type: SUBSCRIPTION_USERS_FETCHED_ERROR });
      
    }
  },
};

export default subscriptionUsersActions;
