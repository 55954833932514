import { getTransactions } from "../../config/config";

export const Transaction_FETCHED_STARTED =
  "Transaction_FETCHED_STARTED";
export const Transaction_FETCHED_SUCCESS =
  "Transaction_FETCHED_SUCCESS";
export const Transaction_FETCHED_ERROR =
  "Transaction_FETCHED_ERROR";

const getTransactionsActions = {
  getTransactions: () => async (dispatch) => {
    try {
      dispatch({ type: Transaction_FETCHED_STARTED });
      const res = await getTransactions();

      if (res) {
        dispatch({ type: Transaction_FETCHED_SUCCESS, payload: res });
      } else {
        dispatch({ type: Transaction_FETCHED_ERROR });
      }
      return res;
    } catch (error) {
      dispatch({ type: Transaction_FETCHED_ERROR });
    }
  },
};

export default getTransactionsActions;
