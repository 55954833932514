import { updatetrial } from "../../config/config";


export const updatetrail_FETCH_STARTED = "updatetrail_FETCH_STARTED";
export const updatetrail_FETCH_SUCCESS = "updatetrail_FETCH_SUCCESS";
export const updatetrail_FETCH_ERROR = "updatetrail_FETCH_ERROR";

const updatetrialActions = {

  updatetrial: (data) => async (dispatch) => {
    try {
      dispatch({
        type: updatetrail_FETCH_STARTED,
      });

      const res = await updatetrial(data);

      if (res) {
        dispatch({
          type: updatetrail_FETCH_SUCCESS,
          payload: res
        });
      } else {
        dispatch({ type: updatetrail_FETCH_ERROR });
      }
      return res;
      
    } catch (error) {
      console.log(error);
      dispatch({
        type: updatetrail_FETCH_ERROR,
        payload: {
          error: "error getting update-trials api",
        },
      });
    }
  },
};

export default updatetrialActions;
