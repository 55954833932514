import {
  getUsersAnalytics, getMarketingAnalytics, lastSignUp, clicksAffiliates, getFrequentUsers,
  lastSignIn, emailPromoAnalytics, getOnlinePTP, getVisitorsData, getClient, getDailyFrequencyUsers, getVisitorOrClientOnline, getHorsesProfile, doFetchVisitor, doFetchOnline
} from '../../config/config';
import { toast } from 'react-toastify'


export const RECEIVED_DATA_SUCCESS = 'RECEIVED_DATA_SUCCESS'
export const RECEIVING_DATA = 'RECEIVING_DATA'

export const SIGNUPS_FETCH_STARTED = 'SIGNUPS_FETCH_STARTED'
export const SIGNUPS_FETCH_SUCCESS = 'SIGNUPS_FETCH_SUCCESS'
export const SIGNUPS_FETCH_ERROR = 'SIGNUPS_FETCH_ERROR'

export const SIGNINS_FETCH_STARTED = 'SIGNINS_FETCH_STARTED'
export const SIGNINS_FETCH_SUCCESS = 'SIGNINS_FETCH_SUCCESS'
export const SIGNINS_FETCH_ERROR = 'SIGNINS_FETCH_ERROR'

export const AFFILIATE_FETCH_STARTED = 'AFFILIATE_FETCH_STARTED'
export const AFFILIATE_FETCH_SUCCESS = 'AFFILIATE_FETCH_SUCCESS'
export const AFFILIATE_FETCH_ERROR = 'AFFILIATE_FETCH_ERROR'

export const FREQUENT_FETCH_STARTED = 'FREQUENT_FETCH_STARTED'
export const FREQUENT_FETCH_SUCCESS = 'FREQUENT_FETCH_SUCCESS';
export const FREQUENT_FETCH_ERROR = 'FREQUENT_FETCH_ERROR';

export const Email_FETCH_STARTED = 'Email_FETCH_STARTED'
export const Email_FETCH_SUCCESS = 'Email_FETCH_SUCCESS';
export const Email_FETCH_ERROR = 'Email_FETCH_ERROR';

export const ONLINENOW_FETCH_STARTED = 'ONLINENOW_FETCH_STARTED'
export const ONLINENOW_FETCH_SUCCESS = 'ONLINENOW_FETCH_SUCCESS';
export const ONLINENOW_FETCH_ERROR = 'ONLINENOW_FETCH_ERROR';

export const RECEIVING_MARKETING_STARTED = 'RECEIVING_MARKETING_STARTED'
export const RECEIVING_MARKETING_SUCESS = 'RECEIVING_MARKETING_SUCESS'
export const RECEIVING_MARKETING_ERROR = 'RECEIVING_MARKETING_ERROR'

export const VISITORS_FETCH_STARTED = 'VISITORS_FETCH_STARTED'
export const VISITORS_FETCH_SUCCESS = 'VISITORS_FETCH_SUCCESS'
export const VISITORS_FETCH_ERROR = 'VISITORS_FETCH_ERROR'

// test
export const CLIENT_AVG_REG_FETCH_STARTED = 'CLIENT_AVG_REG_FETCH_STARTED'
export const CLIENT_AVG_REG_FETCH_SUCCESS = 'CLIENT_AVG_REG_FETCH_SUCCESS'
export const CLIENT_AVG_REG_FETCH_ERROR = 'CLIENT_AVG_REG_FETCH_ERROR'

// get daily frequency users
export const GETDAILY_FREQUENCY_FETCH_STARTED = 'GETDAILY_FREQUENCY_FETCH_STARTED'
export const GETDAILY_FREQUENCY_FETCH_SUCCESS = 'GETDAILY_FREQUENCY_FETCH_SUCCESS'
export const GETDAILY_FREQUENCY_FETCH_ERROR = 'GETDAILY_FREQUENCY_FETCH_ERROR'


export const GET_FETCH_ONLINE_STARTED = 'GET_FETCH_ONLINE_STARTED';
export const GET_FETCH_ONLINE_SUCCESS = 'GET_FETCH_ONLINE_SUCCESS';
export const GET_FETCH_ONLINE_ERROR = 'GET_FETCH_ONLINE_ERROR';

export const FETCH_VISITORS_STARTED = 'FETCH_VISITORS_STARTED';
export const FETCH_VISITORS_SUCCESS = 'FETCH_VISITORS_SUCCESS';
export const FETCH_VISITORS_ERROR = 'FETCH_VISITORS_ERROR';


// visitor or client
export const VISITORORCLIENT_FETCH_STARTED = 'VISITORORCLIENT_FETCH_STARTED'
export const VISITORORCLIENT_FETCH_SUCCESS = 'VISITORORCLIENT_FETCH_SUCCESS'
export const VISITORORCLIENT_FETCH_ERROR = 'VISITORORCLIENT_FETCH_ERROR'

export const GETHORSES_PROFILE_FETCH_STARTED = 'GETHORSES_PROFILE_FETCH_STARTED'
export const GETHORSES_PROFILE_FETCH_SUCCESS = 'GETHORSES_PROFILE_FETCH_SUCCESS'
export const GETHORSES_PROFILE_FETCH_ERROR = 'GETHORSES_PROFILE_FETCH_ERROR'

// export const FETCH_VISITORS_STARTED = 'FETCH_VISITORS_STARTED'
// export const FETCH_VISITORS_SUCCESS = 'FETCH_VISITORS_SUCCESS'
// export const FETCH_VISITORS_ERROR = 'FETCH_VISITORS_ERROR'





export function receiveDataRequest(data) {
  return (dispatch) => {
    dispatch(receivingData());
    getUsersAnalytics(data).then(res => {
      dispatch(receiveDataSuccess(res))
    })
  }
};

export function receiveDataRequestCrone(data) {
  return (dispatch) => {
    getUsersAnalytics(data).then(res => {
      dispatch(receiveDataSuccess(res))
    })
  }
};

export function receiveMarketingRequest(data) {
  return (dispatch) => {
    try {
      dispatch({
        type: RECEIVING_MARKETING_STARTED,
      });
      getMarketingAnalytics(data).then(res => {
        if (res.status === 200) {
          dispatch({
            type: RECEIVING_MARKETING_SUCESS,
            payload: {
              data: res.data,
            },
          });
        } else {
          dispatch({
            type: RECEIVING_MARKETING_ERROR,
          });
        }
      })
    } catch (err) {
      toast.error('ERROR GETTING MARKETING SUGN UP ANALYTICS')
      dispatch({
        type: RECEIVING_MARKETING_ERROR,
      });
    }
  }
};

export function receiveMarketingRequestCrone(data) {
  return (dispatch) => {
    try {
      getMarketingAnalytics(data).then(res => {
        if (res.status === 200) {
          dispatch({
            type: RECEIVING_MARKETING_SUCESS,
            payload: {
              data: res.data,
            },
          });
        } else {
          dispatch({
            type: RECEIVING_MARKETING_ERROR,
          });
        }
      })
    } catch (err) {
      toast.error('ERROR GETTING MARKETING SUGN UP ANALYTICS')
      dispatch({
        type: RECEIVING_MARKETING_ERROR,
      });
    }
  }
};

export function receiveDataSuccess(payload) {
  return {
    type: RECEIVED_DATA_SUCCESS,
    payload
  }
}

export function receivingData() {
  return {
    type: RECEIVING_DATA
  }
}

export const actions = {

  getSignUps: () => async (dispatch) => {
    try {
      dispatch({
        type: SIGNUPS_FETCH_STARTED,
      });
      let response = await lastSignUp()
      dispatch({
        type: SIGNUPS_FETCH_SUCCESS,
        payload: {
          data: response,
        },
      });
    } catch (error) {
      toast.error('ERROR GETTING last 10 Sign UPs')
      dispatch({
        type: SIGNUPS_FETCH_ERROR,
      });
    }
  },
  getSignUpsCrone: () => async (dispatch) => {
    try {
      let response = await lastSignUp()
      dispatch({
        type: SIGNUPS_FETCH_SUCCESS,
        payload: {
          data: response,
        },
      });
    } catch (error) {
      toast.error('ERROR REFRESHING last 10 Sign UPs')
    }
  },

  getAffiliates: (SB, UB) => async (dispatch) => {
    try {
      dispatch({
        type: AFFILIATE_FETCH_STARTED,
      });
      let response = await clicksAffiliates({ SB: SB, UB: UB })
      dispatch({
        type: AFFILIATE_FETCH_SUCCESS,
        payload: {
          data: response,
        },
      });
    } catch (error) {
      toast.error('ERROR GETTING NUMBER OF CLICKS ON AFFILIATES')
      dispatch({
        type: AFFILIATE_FETCH_ERROR,
      });
    }
  },
  getAffiliatesCrone: (SB, UB) => async (dispatch) => {
    try {
      let response = await clicksAffiliates({ SB: SB, UB: UB })
      dispatch({
        type: AFFILIATE_FETCH_SUCCESS,
        payload: {
          data: response,
        },
      });
    } catch (error) {
      toast.error('ERROR AUTO REFRESH AFFILIATES')
    }
  },

  getFrequentUsers: (box) => async (dispatch) => {
    try {
      dispatch({
        type: FREQUENT_FETCH_STARTED,
      });
      let response = await getFrequentUsers({ checked: box })
      dispatch({
        type: FREQUENT_FETCH_SUCCESS,
        payload: {
          data: response,
        },
      });
    } catch (error) {
      toast.error('ERROR GETTING MOST FREQUENT USERS')
      dispatch({
        type: FREQUENT_FETCH_ERROR,
      });
    }
  },

  getSignIns: (staffBox) => async (dispatch) => {
    try {
      dispatch({
        type: SIGNINS_FETCH_STARTED,
      });
      let response = await lastSignIn({ checkBox: staffBox })
      dispatch({
        type: SIGNINS_FETCH_SUCCESS,
        payload: {
          data: response,
        },
      });
    } catch (error) {
      toast.error('ERROR GETTING last 10 Sign Ins')
      dispatch({
        type: SIGNINS_FETCH_ERROR,
      });
    }
  },
  getSignInsCrone: (staffBox) => async (dispatch) => {
    try {
      let response = await lastSignIn({ checkBox: staffBox })
      dispatch({
        type: SIGNINS_FETCH_SUCCESS,
        payload: {
          data: response,
        },
      });
    } catch (error) {
      toast.error('ERROR REFRESHING last 10 Sign Ins')
    }
  },

  getEmail: () => async (dispatch) => {
    try {
      dispatch({
        type: Email_FETCH_STARTED,
      });
      let response = await emailPromoAnalytics()
      dispatch({
        type: Email_FETCH_SUCCESS,
        payload: {
          data: response.data,
        },
      });
    } catch (error) {
      toast.error('ERROR GETTING Email Analytics')
      dispatch({
        type: Email_FETCH_ERROR,
      });
    }
  },
  getEmailCrone: () => async (dispatch) => {
    try {
      let response = await emailPromoAnalytics()
      dispatch({
        type: Email_FETCH_SUCCESS,
        payload: {
          data: response.data,
        },
      });
    } catch (error) {
      toast.error('ERROR REFRESHING Email Analytics')
    }
  },

  getOnlineUsers: (data) => async (dispatch) => {
    try {
      dispatch({
        type: ONLINENOW_FETCH_STARTED,
      });
      let response = await getOnlinePTP(data)
      // console.log(response)
      dispatch({
        type: ONLINENOW_FETCH_SUCCESS,
        payload: {
          data: response.data,
          visitors: response.visitors
        },
      });
    } catch (error) {
      toast.error('ERROR GETTING ONLINE NOW')
      dispatch({
        type: ONLINENOW_FETCH_ERROR,
      });
    }
  },


  getVisitorOrClientOnlineAction: () => async (dispatch) => {
    try {
      dispatch({
        type: VISITORORCLIENT_FETCH_STARTED,
      });
      let response = await getVisitorOrClientOnline()
      // console.log(response)
      dispatch({
        type: VISITORORCLIENT_FETCH_SUCCESS,
        payload: {
          data: response,
        },
      });
    } catch (error) {
      toast.error('ERROR GETTING ONLINE NOW')
      dispatch({
        type: VISITORORCLIENT_FETCH_ERROR,
      });
    }
  },

  doFetchVisitors: (data) => async (dispatch) => {
    console.log(data)
    try {
      dispatch({
        type: FETCH_VISITORS_STARTED
      });
      let response = await doFetchVisitor(data)
      dispatch({
        type: FETCH_VISITORS_SUCCESS,
        payload: { rows: response },
      });
    } catch (error) {
      dispatch({
        type: FETCH_VISITORS_ERROR,
      });
    }

  },

  getFetchOnline: (date) => async (dispatch) => {
    console.log(date)
    try {
      dispatch({
        type: GET_FETCH_ONLINE_STARTED
      });
      let response = await doFetchOnline({ date: (date ? date : 'All') })
      dispatch({
        type: GET_FETCH_ONLINE_SUCCESS,
        payload: { rows: response }
      });
    } catch (error) {
      // toast.error(selectErrorMessage(error))
      dispatch({
        type: GET_FETCH_ONLINE_ERROR,
      });
    }
  },


  getOnlineUsersCrone: (data) => async (dispatch) => {
    try {
      let response = await getOnlinePTP(data)

      dispatch({
        type: ONLINENOW_FETCH_SUCCESS,
        payload: {
          data: response.data,
          visitors: response.visitors
        },
      });
    } catch (error) {

      toast.error('ERROR GETTING ONLINE NOW')
      dispatch({
        type: ONLINENOW_FETCH_ERROR,
      });
    }
  },

  getVisitors: () => async (dispatch) => {
    try {
      dispatch({
        type: VISITORS_FETCH_STARTED,
      });
      let response = await getVisitorsData()
      dispatch({
        type: VISITORS_FETCH_SUCCESS,
        payload: {
          visitorsStat: response.visitorsStat
        },
      });
    } catch (error) {
      toast.error('ERROR GETTING ONLINE NOW')
      dispatch({
        type: VISITORS_FETCH_ERROR,
      });
    }
  },
  getVisitorsCrone: () => async (dispatch) => {
    try {
      let response = await getVisitorsData()
      dispatch({
        type: VISITORS_FETCH_SUCCESS,
        payload: {
          visitorsStat: response.visitorsStat
        },
      });
    } catch (error) {
      toast.error('ERROR GETTING ONLINE NOW')
      dispatch({
        type: VISITORS_FETCH_ERROR,
      });
    }
  },


  getClientAvg: () => async (dispatch) => {
    try {
      dispatch({
        type: CLIENT_AVG_REG_FETCH_STARTED,
      });
      let response = await getClient()
      dispatch({
        type: CLIENT_AVG_REG_FETCH_SUCCESS,
        payload: {
          clientAvGStat: response
        },
      });
    } catch (error) {
      toast.error('ERROR GETTING client')
      dispatch({
        type: CLIENT_AVG_REG_FETCH_ERROR,
      });
    }
  },

  getDailyFrequencyAllUsers: () => async (dispatch) => {
    try {
      dispatch({
        type: GETDAILY_FREQUENCY_FETCH_STARTED,
      });
      let response = await getDailyFrequencyUsers()
      dispatch({
        type: GETDAILY_FREQUENCY_FETCH_SUCCESS,
        payload: {
          getDaily: response,
        },

      });
    } catch (error) {
      toast.error('ERROR GETTING daily frequency users')
      dispatch({
        type: GETDAILY_FREQUENCY_FETCH_ERROR,
      });
    }
  },

  getHorsesProfiles: () => async (dispatch) => {
    try {
      dispatch({
        type: GETHORSES_PROFILE_FETCH_STARTED,
      });
      let response = await getHorsesProfile();
      dispatch({
        type: GETHORSES_PROFILE_FETCH_SUCCESS,
        payload: {
          getHorsesProfile: response?.data,
          getDetailedData: response?.DetailData
        },

      });
    } catch (error) {
      toast.error('ERROR GETTING horses Profile users')
      dispatch({
        type: GETHORSES_PROFILE_FETCH_ERROR,
      });
    }
  },

};




