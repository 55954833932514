import axios from "axios";
// import socketIOClient from "socket.io-client";

const hostApi =
  process.env.NODE_ENV === "development" ? "http://localhost" : ``;
const portApi = process.env.NODE_ENV === "development" ? 3100 : null;

const smartPuntingApi = `${hostApi}${
  portApi ? `:${portApi}` : ``
}/smartpunting`;

const config = {
  headers: { Authorization: `PTPADMIN c91fe46c-7033-4525-87cc-08f81e3f4356` },
};

/*******************USERS***************************/
async function getUsers() {
  const url = `${smartPuntingApi}/users`;
  return await axios.get(url, config).then((response) => response.data.data);
}

async function getUser(id) {
  const url = `${smartPuntingApi}/user`;
  return await axios.post(url, id, config).then((response) => response.data);
}

async function getUserActivity(uid) {
  const url = `${smartPuntingApi}/usera`;
  return await axios.post(url, uid, config).then((response) => response.data);
}

async function getAllUserActivity() {
  const url = `${smartPuntingApi}/usersa`;
  return await axios.get(url, config).then((response) => response.data);
}

async function createUser(data) {
  const url = `${smartPuntingApi}/createUser`;
  return await axios.post(url, data, config).then((response) => response.data);
}

async function updateUser(data) {
  const url = `${smartPuntingApi}/updateUser`;
  return await axios.post(url, data, config).then((response) => response.data);
}

async function deleteUser(data) {
  const url = `${smartPuntingApi}/deleteUser`;
  return await axios.post(url, data, config).then((response) => response.data);
}

/*******************LEADS***************************/
async function getLeads() {
  const url = `${smartPuntingApi}/leads`;
  return await axios.get(url, config).then((response) => response.data.data);
}

async function getLead(id) {
  const url = `${smartPuntingApi}/lead`;
  return await axios.post(url, id, config).then((response) => response.data);
}

async function deleteLead(data) {
  const url = `${smartPuntingApi}/deleteLead`;
  return await axios.post(url, data, config).then((response) => response.data);
}

async function fbLeadss() {
  const url = `${smartPuntingApi}/fbleadss`;
  return await axios.get(url, config).then((response) => response);
}

/*******************HOMETABLE***************************/
async function getHomeTableInfo() {
  const url = `${smartPuntingApi}/hometableinfo`;
  return await axios.get(url, config).then((response) => response.data.data);
}

async function getHomeTable(id) {
  const url = `${smartPuntingApi}/homeTable`;
  return await axios.post(url, id, config).then((response) => response.data);
}

async function createHomeTable(data) {
  const url = `${smartPuntingApi}/createHomeTable`;
  return await axios.post(url, data, config).then((response) => response.data);
}

async function updateHomeTable(data) {
  const url = `${smartPuntingApi}/updateHomeTable`;
  return await axios.post(url, data, config).then((response) => response.data);
}

async function deleteHomeTable(data) {
  const url = `${smartPuntingApi}/deleteHomeTable`;
  return await axios.post(url, data, config).then((response) => response.data);
}

/*******************API*************************/
async function updateLadbrokesId() {
  const url = `${smartPuntingApi}/updateLadbrokesId`;
  return await axios.get(url, config).then((response) => response);
}

async function updateLadbrokesPrices() {
  const url = `${smartPuntingApi}/updateFuturRacesLBPrices`;
  return await axios.get(url, config).then((response) => response);
}

async function updateAllTrackCondition() {
  const url = `${smartPuntingApi}/updateAllTrackCondition`;
  return await axios.get(url, config).then((response) => response.data);
}

async function updateAllLadbrokesPrices() {
  const url = `${smartPuntingApi}/updateLadbrokesPrices`;
  return await axios.get(url, config).then((response) => response);
}

async function updateAllLadbrokesOPrices() {
  const url = `${smartPuntingApi}/updateLadbrokesOPrices`;
  return await axios.get(url, config).then((response) => response);
}

async function sendDailySelection() {
  const url = `${smartPuntingApi}/sendDailySelection`;
  return await axios.get(url, config).then((response) => response);
}

async function sendSilverSelection() {
  const url = `${smartPuntingApi}/sendSilverSelection`;
  return await axios.get(url, config).then((response) => response);
}

async function sendGoldSelection() {
  const url = `${smartPuntingApi}/sendGoldSelection`;
  return await axios.get(url, config).then((response) => response);
}

async function getAllRodRules() {
  const url = `${smartPuntingApi}/updateRodMaxPrices`;
  return await axios.get(url, config).then((response) => response);
}

async function removeLowPTP() {
  const url = `${smartPuntingApi}/removelowptp`;
  return await axios.get(url, config).then((response) => response);
}

async function rmvABND() {
  const url = `${smartPuntingApi}/removeabnd`;
  return await axios.get(url, config).then((response) => response);
}

async function sendNextBet() {
  const url = `${smartPuntingApi}/sendNextBet`;
  return await axios.get(url, config).then((response) => response);
}

async function automateToday(data) {
  const url = `${smartPuntingApi}/automateToday`;
  return await axios.post(url, data, config).then((response) => response);
}

async function deleteTodayRaces() {
  const url = `${smartPuntingApi}/deleteTodayRaces`;
  return await axios.get(url, config).then((response) => response);
}

async function autoSilverResults() {
  const url = `${smartPuntingApi}/autoSilverResults`;
  return await axios.get(url, config).then((response) => response);
}

async function autoGoldResults() {
  const url = `${smartPuntingApi}/autoGoldResults`;
  return await axios.get(url, config).then((response) => response);
}

/*******************RACES***********************/
async function getRaces(data) {
  const url = `${smartPuntingApi}/races`;
  return await axios
    .post(url, data, config)
    .then((response) => response.data.data);
}

async function getRace(id) {
  const url = `${smartPuntingApi}/race`;
  return await axios.post(url, id, config).then((response) => response.data);
}

async function createRace(data) {
  const url = `${smartPuntingApi}/createRace`;
  return await axios.post(url, data, config).then((response) => response.data);
}

async function updateRace(data) {
  const url = `${smartPuntingApi}/updateRace`;
  return await axios.post(url, data, config).then((response) => response.data);
}

async function deleteRace(data) {
  const url = `${smartPuntingApi}/deleteRace`;
  return await axios.post(url, data, config).then((response) => response.data);
}

async function removeFromGold(data) {
  const url = `${smartPuntingApi}/removeFromGold`;
  return await axios.post(url, data, config).then((response) => response.data);
}

async function getSelectedRace(data) {
  const url = `${smartPuntingApi}/selectedraces`;
  return await axios
    .post(url, data, config)
    .then((response) => response.data.data);
}

async function calculateOdds(data) {
  const url = `${smartPuntingApi}/calculateodds`;
  return await axios.post(url, data, config).then((response) => response.data);
}

async function resultRace(data) {
  const url = `${smartPuntingApi}/resultrace`;
  return await axios.post(url, data, config).then((response) => response.data);
}

async function getClientsOdds(data) {
  const url = `${smartPuntingApi}/clientsodds`;
  return await axios.post(url, data, config).then((response) => response.data);
}

async function automateRaceSelectionsSilver() {
  const url = `${smartPuntingApi}/automateRaceSelectionsSilver`;
  return await axios.get(url, config).then((response) => response);
}

async function automateRaceSelectionsGold() {
  const url = `${smartPuntingApi}/automateRaceSelectionsGold`;
  return await axios.get(url, config).then((response) => response);
}

const generateRaceSelectionsPlatinum = async () => {
  const url = `${smartPuntingApi}/generateRaceSelectionsPlatinum`;
  return await axios.get(url, config).then((response) => response);
};

function fetchRaces(data) {
  const url = `${smartPuntingApi}/listRaces`;
  return axios.post(url, data, config).then((response) => response);
}

function saveCalcResults(data) {
  const url = `${smartPuntingApi}/saveClientRaces`;
  return axios.post(url, data, config).then((response) => response.data);
}

function saveClient(data) {
  const url = `${smartPuntingApi}/saveClient`;
  return axios.post(url, data, config).then((response) => response.data);
}

function checkAvailability(clientID) {
  let data = {
    clientID: clientID,
  };
  const url = `${smartPuntingApi}/checkavailability`;
  return axios.post(url, data, config).then((response) => response.data);
}

function getClients() {
  const url = `${smartPuntingApi}/retrieveClients`;
  return axios.get(url, config).then((response) => response.data);
}

async function deleteClient(data) {
  const url = `${smartPuntingApi}/deleteClient`;
  return await axios.post(url, data, config).then((response) => response.data);
}

async function getClient(id) {
  const url = `${smartPuntingApi}/client`;
  return await axios.post(url, id, config).then((response) => response.data);
}

async function createClient(data) {
  const url = `${smartPuntingApi}/createClient`;
  return await axios.post(url, data, config).then((response) => response.data);
}

async function updateClient(data) {
  const url = `${smartPuntingApi}/updateClient`;
  return await axios.post(url, data, config).then((response) => response.data);
}

async function checkRaceGoldSilver(data) {
  const url = `${smartPuntingApi}/checkRaceGoldSilver`;
  return await axios
    .post(url, data, config)
    .then((response) => response.data.data);
}

async function sendCustomEmail(data) {
  const url = `${smartPuntingApi}/sendCustomEmail`;
  return await axios.post(url, data, config).then((response) => response.data);
}

const loadTodayRacesForMsgs = async () => {
  const url = `${smartPuntingApi}/loadTodayRacesForMsgs`;
  return await axios.get(url, config).then((response) => response.data);
};

async function stopMessages() {
  const url = `${smartPuntingApi}/stopMessages`;
  return await axios.get(url, config).then((response) => response.data);
}

async function updatePriceFromPTP() {
  const url = `${smartPuntingApi}/updatePriceFromPTP`;
  return await axios.get(url, config).then((response) => response.data);
}

async function showTodayRaces() {
  const url = `${smartPuntingApi}/showTodayRaces`;
  return await axios.get(url, config).then((response) => response.data);
}

export {
  getAllUserActivity,
  fbLeadss,
  getUserActivity,
  getLead,
  getLeads,
  deleteLead,
  getUsers,
  getUser,
  deleteUser,
  createUser,
  updateUser,
  getRaces,
  getRace,
  deleteRace,
  createRace,
  updateRace,
  getSelectedRace,
  calculateOdds,
  resultRace,
  getClientsOdds,
  updateLadbrokesId,
  updateLadbrokesPrices,
  updateAllLadbrokesPrices,
  updateAllLadbrokesOPrices,
  sendDailySelection,
  sendNextBet,
  automateToday,
  sendSilverSelection,
  sendGoldSelection,
  automateRaceSelectionsSilver,
  automateRaceSelectionsGold,
  generateRaceSelectionsPlatinum,
  autoSilverResults,
  autoGoldResults,
  getAllRodRules,
  fetchRaces,
  saveCalcResults,
  checkAvailability,
  getClients,
  deleteClient,
  getClient,
  createClient,
  updateClient,
  checkRaceGoldSilver,
  updateAllTrackCondition,
  removeFromGold,
  saveClient,
  getHomeTableInfo,
  getHomeTable,
  createHomeTable,
  updateHomeTable,
  deleteHomeTable,
  sendCustomEmail,
  deleteTodayRaces,
  stopMessages,
  removeLowPTP,
  rmvABND,
  updatePriceFromPTP,
  showTodayRaces,
  loadTodayRacesForMsgs,
};
